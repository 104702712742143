import React, { forwardRef, createRef } from 'react';
// import { truncate } from '../../utils/GeneralUtils';
import CommonToast from './CommonToast';
import CopyIcon from '../../assets/img/copy.svg';
import NewTabIcon from '../../assets/img/new-tab.svg';

const AccountInfo = forwardRef((props, ref) => {
  const toastRef = createRef();
  const { account } = props;
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(account);
    toastRef.current.handleOpen();
  }

  return (
    <>
      <CommonToast
        toastVariant='dark'
        infoMessage='Copied to Clipboard!'
        ref={toastRef}
      />
      <div>
        <div className="account-modal__header">
          <span>Wallet</span>
          <span>Connected with MetaMask</span>
        </div>
        <div className="account-modal__address">
          <span>
            {account}
          </span>
        </div>
        <div className="account-modal__links">
          <div className="account-modal__newtab">
            <a
              onClick={()=> window.open(`https://bscscan.com/address/${account}`, "_blank")}
              href 
            >
              <img src={NewTabIcon} alt="Open New Tab Icon" />View on explorer
            </a>
          </div>
          <div className="account-modal__clipboard">
            <a 
              onClick={copyToClipboard} 
              href 
            >
              <img src={CopyIcon} alt="Copy Icon" />Copy Address
            </a>
          </div>
        </div>
      </div>
    </>
  )
});
export default AccountInfo;