import Web3 from 'web3';
import {
  metamaskErrorMessage,
  edogeSingle,
  edaoBNB,
  edogeFarmContract,
  edaoFarmContract,
  oldFarmContract,
  nftContract,
} from './Constants';
import abi from '../artifacts/AbiErc20';
import abiEdogeFarm from '../artifacts/AbiEdogeFarm';
import abiEdaoFarm from '../artifacts/AbiEdaoFarm';
import abiOldFarm from '../artifacts/AbiOldFarm';
import abi721 from '../artifacts/AbiErc721';

const loadFarm = (web3, name = null) => {
  try {
    if (name === 'edoge') {
      return new web3.eth.Contract(abiEdogeFarm, edogeFarmContract);
    }
  
    return new web3.eth.Contract(abiEdaoFarm, edaoFarmContract);
  } catch (error) {
    throw new Error(error);
  }
};

const loadERCToken = (web3, tokenAddress) => {
  try {
    return new web3.eth.Contract(abi, tokenAddress);
  } catch (error) {
    throw new Error(error);
  }
};

const loadERC721Token = (web3) => {
  try {
    return new web3.eth.Contract(abi721, nftContract);
  } catch (error) {
    throw new Error(error);
  }
};

export const loadWeb3 = async () => {
  try {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
  
      return { web3, account: accounts[0] }; 
    }
  } catch (error) {
    console.error(error)
    throw new Error(metamaskErrorMessage);
  }
};

export const allowance = async (tokenAddress) => {
  try {
    let farmContract;
    const { web3, account } = await loadWeb3();
    const flourToken = loadERCToken(web3, tokenAddress);

    if (tokenAddress === edogeSingle) {
      farmContract = edogeFarmContract;
    } else {
      farmContract = edaoFarmContract;
    }

    const allowance = await flourToken.methods.allowance(
      account, farmContract
    ).call();

    return allowance;
  } catch (error) {
    throw new Error(error);
  }
};

export const deposited = async (tokenName) => {
  try {
    const { web3, account } = await loadWeb3();
    let contract, deposited, pid = 0;

    if (tokenName === 'edoge') {
      contract = new web3.eth.Contract(
        abiEdogeFarm,
        edogeFarmContract
      );

      deposited = await contract.methods.deposited(pid, account).call();
      deposited = web3.utils.fromWei(deposited.toString(), 'nanoether');
    } else if (tokenName === 'edaoBNB') {
      pid = 1;
      contract = new web3.eth.Contract(
        abiEdaoFarm,
        edaoFarmContract
      );

      deposited = await contract.methods.deposited(pid, account).call();
      deposited = web3.utils.fromWei(deposited.toString(), 'ether');
    } else {
      contract = new web3.eth.Contract(
        abiEdaoFarm,
        edaoFarmContract
      );

      deposited = await contract.methods.deposited(pid, account).call();
      deposited = web3.utils.fromWei(deposited.toString(), 'ether');
    }

    return deposited;
  } catch (error) {
    throw new Error(error);
  }
};

export const approve = (tokenAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      let farmContract;
      const { web3, account } = await loadWeb3();
      const flourToken = loadERCToken(web3, tokenAddress);

      if (tokenAddress === edogeSingle) {
        farmContract = edogeFarmContract;
      } else {
        farmContract = edaoFarmContract;
      }

      await flourToken.methods.approve(
        farmContract,
        '100000000000000000000000000000000000000000'
      ).send({
        from: account
      }).then((contractInstance) => {
        console.log(contractInstance);
        resolve({ contractInstance });
      }).catch((error) => {
        reject(error);
      })
    } catch (error) {
      reject(error);
    }
  });
};

export const deposit = (stakeValue, tokenAddress, pid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { web3, account } = await loadWeb3();

      if (tokenAddress === edogeSingle) {
        const contract = loadFarm(web3, 'edoge');
        const amount = web3.utils.toWei(stakeValue.toString(), 'nanoether');
        
        await contract.methods.deposit(
          pid, amount
        ).send({
          from: account
        }).then(async (contractInstance) => {
          try {
            const totalDeposited = await deposited('edoge');
            resolve({ contractInstance, totalDeposited })
          } catch (error) {
            reject(error);
          }
        }).catch((error) => {
          reject(error);
        });
      } else {
        const contract = loadFarm(web3);
        const amount = web3.utils.toWei(stakeValue.toString(), 'ether');
        
        await contract.methods.deposit(
          pid, amount
        ).send({
          from: account
        }).then(async (contractInstance) => {
          try {
            let totalDeposited;
            if (tokenAddress === edaoBNB) {
              totalDeposited = await deposited('edaoBNB');
            } else {
              totalDeposited = await deposited('edogeEdao')
            }

            resolve({ contractInstance, totalDeposited });
          } catch (error) {
            reject(error);
          }
        }).catch((error) => {
          reject(error);
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const withdraw = (stakeValue, tokenAddress, pid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { web3, account } = await loadWeb3();

      if (tokenAddress === edogeSingle) {
        const contract = loadFarm(web3, 'edoge');
        const amount = web3.utils.toWei(stakeValue.toString(), 'nanoether');

        await contract.methods.withdraw(
          pid, amount
        ).send({
          from: account
        }).then(async (contractInstance) => {
          try {
            const totalDeposited = await deposited('edoge');
            resolve({ contractInstance, totalDeposited });
          } catch (error) {
            reject(error);
          }
        }).catch((error) => {
          reject(error);
        });
      } else {
        const contract = loadFarm(web3);
        const amount = web3.utils.toWei(stakeValue.toString(), 'ether');
        
        await contract.methods.withdraw(
          pid, amount
        ).send({
          from: account
        }).then(async (contractInstance) => {
          try {
            let totalDeposited;
            if (tokenAddress === edaoBNB) {
              totalDeposited = await deposited('edaoBNB');
            } else {
              totalDeposited = await deposited('edogeEdao');
            }

            resolve({ contractInstance, totalDeposited });
          } catch (error) {
            reject(error);
          }
        }).catch((error) => {
          reject(error);
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const endBlock = async (tokenAddress) => {
  try {
    const { web3 } = await loadWeb3();

    let contract, blockNumber;
    if (tokenAddress === edogeSingle) {
      contract = loadFarm(web3, 'edoge');

      blockNumber = await contract.methods.endBlock().call();
    } else {
      contract = loadFarm(web3);

      blockNumber = await contract.methods.endBlock().call();
    }

    return blockNumber;
  } catch (error) {
    throw new Error(error);
  }
};

export const boostInfo = async (tokenAddress) => {
  try {
    const { web3, account } = await loadWeb3();

    let contract;
    if (tokenAddress === edogeSingle) {
      contract = loadFarm(web3, 'edoge');
    } else {
      contract = loadFarm(web3);
    }

    const info = await contract.methods.boostInfo(account).call();

    return info;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const emergencyWithdraw = (pid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { web3, account } = await loadWeb3();

      const contract = new web3.eth.Contract(
        abiOldFarm,
        oldFarmContract,
      );

      await contract.methods.emergencyWithdraw(pid).send({
        from: account
      }).then(async (contractInstance) => {
        resolve({ contractInstance });
      }).catch((error) => {
        reject(error);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const equipNFT = (tokenId, tokenAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { web3, account } = await loadWeb3();
      const contractErc721 = loadERC721Token(web3);

      let contract;
      if (tokenAddress === edogeSingle) {
        contract = loadFarm(web3, 'edoge');

        await contractErc721.methods.approve(
          edogeFarmContract, tokenId
        ).send({ from: account });
      } else {
        contract = loadFarm(web3);

        await contractErc721.methods.approve(
          edaoFarmContract, tokenId
        ).send({ from: account });
      }

      await contract.methods.equip_nft(
        tokenId
      ).send({
        from: account
      }).then((contractInstance) => {
        resolve({ contractInstance });
      }).catch((error) => {
        reject(error);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const claimNFTRewards = (tokenAddress, pid) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { web3, account } = await loadWeb3();

      let contract;
      if (tokenAddress === edogeSingle) {
        contract = loadFarm(web3, 'edoge');
      } else {
        contract = loadFarm(web3);
      }

      contract.methods.claimNFTBoostRewards(
        pid,
      ).send({
        from: account
      }).then((contractInstance) => {
        resolve({ contractInstance });
      }).catch((error) => {
        reject(error);
      });
    } catch (error) {
      reject(error);
    }
  });
};
