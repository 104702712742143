import Web3 from 'web3';
import { abi as IUniswapV2Pair } from '@pancakeswap-libs/pancake-swap-core/build/IPancakePair.json';
import { BigNumber } from "bignumber.js";
import {
  apiEndpoint,
  edogeFarmContract,
  edaoFarmContract,
  apiPancakeEdoge,
  apiPancakeEdao,
  apiPanckaeBNB,
  edogeSingle,
  edaoBNB,
  edogeEdao,
} from '../utils/Constants';
import { numberWithComma } from './GeneralUtils';
import abi from '../artifacts/AbiErc20';
import abiEdaoFarm from '../artifacts/AbiEdaoFarm';
import abiEdogeFarm from '../artifacts/AbiEdogeFarm';

const web3 = new Web3(apiEndpoint);

export const webProviderToken = async (address) => {
  try {
    const ercToken = await new web3.eth.Contract(abi, address);
    return ercToken;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const convertFromWei = (value, conversion = null) => {
  try {

    let result;
    if (conversion) {
      result = web3.utils.fromWei(value, conversion);
    } else {
      result = web3.utils.fromWei(value, 'ether');
    }

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const convertToWei = (value) => {
  try {
    const result = web3.utils.toWei(value, 'nanoether');

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const providerTokenTotalSupply = async (address) => {
  try {
    const tokenContract = new web3.eth.Contract(IUniswapV2Pair, address);

    const totalSupply = await tokenContract.methods.totalSupply().call();
    return totalSupply;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPriceInUSD = async (tokenName) => {
  try {
    let response;

    if (tokenName === 'edoge') {
      response = await fetch(apiPancakeEdoge);
    } else if (tokenName === 'edao') {
      response = await fetch(apiPancakeEdao);
    } else if (tokenName === 'bnb') {
      response = await fetch(apiPanckaeBNB);
    }

    const priceData = await response.json();
    return priceData.data.price;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const providerToken9Decimal = async (address) => {
  try {
    const tokenContract = new web3.eth.Contract(IUniswapV2Pair, address);
    const totalReserves = await tokenContract.methods.getReserves().call();
    return [convertToWei(totalReserves[0].toString()), totalReserves[1]];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const providerTokenReserves = async (address) => {
  try {
    const tokenContract = new web3.eth.Contract(IUniswapV2Pair, address);
    const totalReserves = await tokenContract.methods.getReserves().call();
    return [totalReserves[0], totalReserves[1]];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const webProviderFarm = async (name) => {
  try {
    let contract;
    if (name === 'edao') {
      contract = new web3.eth.Contract(abiEdaoFarm, edaoFarmContract);
    } else {
      contract = new web3.eth.Contract(abiEdogeFarm, edogeFarmContract);
    }

    return contract;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const webProviderRewardPerBlock = async (name) => {
  try {
    const contract = await webProviderFarm(name);
    
    let rewardPerBlock;
    if (name === 'edao') {
      rewardPerBlock = await contract.methods.rewardPer130Blocks().call();
    } else {
      rewardPerBlock = await contract.methods.rewardPer1000Blocks().call();
    }

    return rewardPerBlock;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const loadFarm = (name = null) => {
  if (name === 'edoge') {
    return new web3.eth.Contract(
      abiEdogeFarm,
      edogeFarmContract,
    );
  }

  return new web3.eth.Contract(
    abiEdaoFarm,
    edaoFarmContract,
  );
};

export const calculateRewards = async (account) => {
  const edogeContract = loadFarm('edoge');
  const edaoContract = loadFarm();

  try {
    const edogePending = await edogeContract.methods.pending(0, account).call();
    const edaoRewardsPending1 = convertFromWei(edogePending.toString());
    const rewards1 = parseFloat(edaoRewardsPending1).toFixed(18);

    const edaoPending = await edaoContract.methods.pending(1, account).call();
    const edaoRewardsPending2 = convertFromWei(edaoPending.toString());
    const rewards2 = parseFloat(edaoRewardsPending2).toFixed(18);

    const edogeEdaoPending = await edaoContract.methods.pending(0, account).call();
    const edaoRewardsPending3 = convertFromWei(edogeEdaoPending.toString());
    const rewards3 = parseFloat(edaoRewardsPending3).toFixed(18);

    return {
      rewards1,
      rewards2,
      rewards3,
    }

  } catch (error) {
    throw error;
  }
};

export const calculateAllApys = async () => {
  try {
    let edogeTVL, edaoBnbTVL, edogeEdaoTVL,
    apyEdoge, apyEdaoBnb, apyEdogeEdao;

    let edogePrice = 0;
    let edaoPrice = 0;
    let bnbPrice = 0;

    const edogeSingleToken = await webProviderToken(edogeSingle);
    const edaoBNBToken = await webProviderToken(edaoBNB);
    const edogeEdaoToken = await webProviderToken(edogeEdao);

    edogePrice = await getPriceInUSD('edoge');
    edaoPrice = await getPriceInUSD('edao');
    bnbPrice = await getPriceInUSD('bnb');

    const blocksPerYear = 28800 * 365;

    const rewardPer1000Blocks = await webProviderRewardPerBlock('edoge');
    const rewardPerBlock = rewardPer1000Blocks / 1000;
    const edogeRewardPerBlock = edaoPrice * rewardPerBlock;

    const rewardPer130Blocks = await webProviderRewardPerBlock('edao');
    const edaoRewardPerBlock = rewardPer130Blocks / 130;
    const edaoYearlyRewards = new BigNumber(edaoPrice)
      .times(edaoRewardPerBlock)
      .times(blocksPerYear);

    const LPDepositedEdoge = await edogeSingleToken.methods
      .balanceOf(edogeFarmContract)
      .call();
    
    const LPEdogeConverted = convertFromWei(LPDepositedEdoge.toString(), 'nanoether');

    const LPDepositedEdaoBNB = await edaoBNBToken.methods
      .balanceOf(edaoFarmContract)
      .call();
    const LPEdaoConverted = convertFromWei(LPDepositedEdaoBNB.toString());

    const LPDepositedEdogeEdao = await edogeEdaoToken.methods
      .balanceOf(edaoFarmContract)
      .call();
    const LPEdogeEdaoConverted = convertFromWei(
      LPDepositedEdogeEdao.toString()
    );

    const totalStakedEdoge = LPEdogeConverted * edogePrice;

    const priceCummulativeEdaoBNB = new BigNumber(edaoPrice * bnbPrice).sqrt();
    const totalReserveEdaoBNB = await providerTokenReserves(edaoBNB);

    const tokenCummulativeEdaoBNB = new BigNumber(totalReserveEdaoBNB[0])
      .times(totalReserveEdaoBNB[1])
      .sqrt();

    const totalSupplyEdaoBNB = await providerTokenTotalSupply(edaoBNB);
    const LPTokenPriceEdaoBNB = tokenCummulativeEdaoBNB
      .times(priceCummulativeEdaoBNB)
      .times(2)
      .div(totalSupplyEdaoBNB);

    edaoBnbTVL = LPEdaoConverted * LPTokenPriceEdaoBNB;
    const totalPriceTokensEdaoBNB = new BigNumber(LPTokenPriceEdaoBNB).times(
      LPEdaoConverted
    );

    const priceCummulativeEdogeEdao = new BigNumber(
      edaoPrice * edogePrice
    ).sqrt();

    const totalReserveEdogeEdao = await providerToken9Decimal(
      edogeEdao
    );

    const tokenCummulativeEdogeEdao = new BigNumber(totalReserveEdogeEdao[0])
      .times(totalReserveEdogeEdao[1])
      .sqrt();

    const totalSupplyEdogeEdao = await providerTokenTotalSupply(edogeEdao);
    const LPTokenPriceEdogeEdao = tokenCummulativeEdogeEdao
      .times(priceCummulativeEdogeEdao)
      .times(2)
      .div(totalSupplyEdogeEdao);

    edogeEdaoTVL = LPEdogeEdaoConverted * LPTokenPriceEdogeEdao;
    const totalPriceTokensEdogeEdao = new BigNumber(
      LPTokenPriceEdogeEdao
    ).times(LPEdogeEdaoConverted);

    apyEdoge = (edogeRewardPerBlock / totalStakedEdoge) * blocksPerYear * 0.1 * 100;

    edogeTVL = numberWithComma(parseFloat(totalStakedEdoge.toFixed(2)));
    apyEdoge = numberWithComma(parseFloat(apyEdoge.toFixed(2)));

    apyEdaoBnb = edaoYearlyRewards
      .div(totalPriceTokensEdaoBNB)
      .times(100)
      .times(3)
      .div(10);

    edaoBnbTVL = numberWithComma(parseFloat(edaoBnbTVL.toFixed(2)));
    apyEdaoBnb = numberWithComma(parseFloat(apyEdaoBnb.toFixed(2)));

    apyEdogeEdao = edaoYearlyRewards
      .div(totalPriceTokensEdogeEdao)
      .times(100)
      .times(6)
      .div(10);

    edogeEdaoTVL = numberWithComma(parseFloat(edogeEdaoTVL.toFixed(2)));
    apyEdogeEdao = numberWithComma(parseFloat(apyEdogeEdao.toFixed(2)));

    return {
      edaoBnbTVL,
      edogeTVL,
      edogeEdaoTVL,
      apyEdoge,
      apyEdaoBnb,
      apyEdogeEdao,
    };
  } catch (error) {
    throw error;
  }
}