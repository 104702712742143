import React, {
  useState,
  createRef,
} from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import CommonToast from '../common/CommonToast';
import {
  txSuccessMessage,
  txErrorMessage,
  legacyWithdrawalLabel,
} from '../../utils/Constants';
import { emergencyWithdraw } from '../../utils/EthWebProvider';
import '../../assets/css/components/farm/oldfarms.css';

const toastRef = createRef();
const OldFarms = () => {
  const [ toastVariant, setToastVariant ] = useState('success');
  const [ successMessage, setSuccessMessage ] = useState('');
  const [ edogeButtonLoading, setEdogeButtonLoading ] = useState(false);
  const [ edaoButtonLoading, setEdaoButtonLoading ] = useState(false);
  const [ edogeEdaoButtonLoading, setEdogeEdaoButtonLoading ] = useState(false);

  const emergencyWithdrawal = async (pid) => {
    if (pid === 2) {
      setEdogeButtonLoading(true);
    } else if (pid === 1) {
      setEdaoButtonLoading(true);
    } else if (pid === 0) {
      setEdogeEdaoButtonLoading(true);
    }

    try {
      const { contractInstance: { transactionHash }} = await emergencyWithdraw(pid);
      setToastVariant('success');
      setSuccessMessage(txSuccessMessage(transactionHash));
      toastRef.current.handleOpen();

      if (pid === 2) {
        setTimeout(() => {
          setEdogeButtonLoading(false);
        }, 1000);
      } else if (pid === 1) {
        setTimeout(() => {
          setEdaoButtonLoading(false);
        }, 1000);
      } else if (pid === 0) {
        setTimeout(() => {
          setEdogeEdaoButtonLoading(false);
        }, 1000);
      }
    } catch (error) {
      setToastVariant('danger');
      toastRef.current.handleOpen();

      if (pid === 2) {
        setTimeout(() => {
          setEdogeButtonLoading(false);
        }, 1000);
      } else if (pid === 1) {
        setTimeout(() => {
          setEdaoButtonLoading(false);
        }, 1000);
      } else if (pid === 0) {
        setTimeout(() => {
          setEdogeEdaoButtonLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <>
      <CommonToast 
        toastVariant={toastVariant} 
        ref={toastRef}
        successMessage={successMessage}
        dangerMessage={txErrorMessage}
      />
      <Row className="tw-mt-6 container tw-mx-0 tw-px-0">
        <Row className="tw-mx-0 tw-px-0">
          <h5 className="tw-w-auto tw-flex-none text-white tw-pr-5">Old Farm LPs</h5>
          <hr className="tw-w-auto tw-flex-1 text-white mt-2" />
        </Row>
      </Row>
      <Container fluid className="tw-px-0">
        <Row className="tw-mt-6 container old-elondoge-table__header text-center tw-hidden md:tw-flex">
          <Col>
            <span className="span__edoge">Edoge BNB LP</span>
          </Col>
          <Col>
            <span className="span__edao">Edao BNB LP</span>
          </Col>
          <Col>
            <span className="span__edoge-edao">Edoge Edao LP</span>
          </Col>
        </Row>
        <Row className="tw-mt-6 container old-elondoge-table__body tw-mx-0 tw-flex-col md:tw-flex-row tw-py-8 md:tw-py-0">
          <Col className="tw-block md:tw-grid tw-text-center">
            <span className="span__edoge tw-text-center tw-block md:tw-hidden tw-mb-3">Edoge BNB LP</span>
            <button
              className="old-elondoge__btn old-elondoge__btn--lg old-elondoge__btn--edoge tw-text-sm xl:tw-text-base tw-font-normal xl:tw-font-semibold"
              disabled={edogeButtonLoading}
              onClick={() => emergencyWithdrawal(2)}
            >
              {edogeButtonLoading ? (
                <p className="tw-animate-spin h-5 w-5 mr-3">
                  <FontAwesomeIcon icon={faCircleNotch} />
                </p>
              ): legacyWithdrawalLabel}
            </button>
          </Col>
          <Col className="tw-block md:tw-grid tw-mt-8 md:tw-mt-0 tw-text-center">
            <span className="span__edao tw-text-center tw-block md:tw-hidden tw-mb-3">Edao BNB LP</span>
            <button
              className="old-elondoge__btn old-elondoge__btn--lg old-elondoge__btn--edao tw-text-sm xl:tw-text-base tw-font-normal xl:tw-font-semibold"
              disabled={edaoButtonLoading}
              onClick={() => emergencyWithdrawal(1)}
            >
              {edaoButtonLoading ? (
                <p className="tw-animate-spin h-5 w-5 mr-3">
                  <FontAwesomeIcon icon={faCircleNotch} />
                </p>
              ): legacyWithdrawalLabel}
            </button>
          </Col>
          <Col className="tw-block md:tw-grid tw-mt-8 md:tw-mt-0 tw-text-center">
            <span className="span__edoge-edao tw-text-center tw-block md:tw-hidden tw-mb-3">Edoge Edao LP</span>
            <button
              className="old-elondoge__btn old-elondoge__btn--lg old-elondoge__btn--edoge-edao tw-text-sm xl:tw-text-base tw-font-normal xl:tw-font-semibold"
              disabled={edogeEdaoButtonLoading}
              onClick={() => emergencyWithdrawal(0)}
            >
              {edogeEdaoButtonLoading ? (
                <p className="tw-animate-spin h-5 w-5 mr-3">
                  <FontAwesomeIcon icon={faCircleNotch} />
                </p>
              ): legacyWithdrawalLabel}
            </button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default OldFarms;
