import { useEffect, useCallback, useState } from 'react';
import Banner from './components/Banner';
import Universe from './components/Universe';
import Tokens from './components/Tokens';
import Team from './components/Team';
// import PartnersIntegrations from './components/PartnersIntegrations';
import Faq from './components/Faq';
// import HowToBuy from './components/HowToBuy';
import Header from './partials/Header';
import Footer from './partials/Footer';
import { withTranslation } from 'react-i18next';

function Home({ i18n }) {
  const languages = [
    {
      code: 'zh',
      label: 'Chinese',
    },
    {
      code: 'nl',
      label: 'Dutch',
    },
    {
      code: 'en',
      label: 'English',
    },
    {
      code: 'ko',
      label: 'Korean',
    },
    {
      code: 'ru',
      label: 'Russian',
    },
  ];

  const [selectedLanguage, setLanguage] = useState();

  const handleLanguageChange = (e) => {
    setLocalization(e.target.value);
  };

  const setLocalization = useCallback(
    (lang) => {
      setLanguage(lang);
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  useEffect(() => {
    const lastLanguage = localStorage.getItem('i18nextLng')
      ? localStorage.getItem('i18nextLng')
      : 'en';
    setLocalization(lastLanguage);
  }, [setLocalization]);

  return (
    <>
      <Header
        languages={languages}
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange}
      />
      <Banner />
      <Universe />
      <div className="tw-bg-gray-1">
        <div className="tw-w-11/12 xl:tw-w-10/12 tw-mx-auto tw-pt-16 lg:tw-pt-20 xl:tw-pt-40 tw-pb-20 lg:tw-pb-24 xl:tw-pb-48">
          <Tokens />
          <Team />
          {/* <PartnersIntegrations /> */}
          <Faq />
          {/* <HowToBuy /> */}
        </div>
      </div>
      <Footer
        languages={languages}
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange}
      />
    </>
  );
}

export default withTranslation(['translation', 'common'])(Home);
