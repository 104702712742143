import Sprite from '../../../assets/images/sprite.svg';
import Accordion from '../../../components/common/Accordion';
import '../../../assets/css/components/home/faq.css';
import { withTranslation } from 'react-i18next';

function Faq({ t }) {
  return (
    <div className="tw-mt-12 lg:tw-mt-20 xl:tw-mt-40">
      <h3 className="heading">{t('common:faqs')}</h3>
      <div className="tw-mt-12 tw-space-y-9">
        <Accordion
          title={
            <div className="accordion__title-container">
              <svg className="accordion__caret">
                <use xlinkHref={`${Sprite}#caret-down`} />
              </svg>
              <div className="accordion__title">{t(`home.faqs.1.question`)}</div>
            </div>
          }
          panel={
            <>
              <p className="accordion__panel-text">{t('home.faqs.1.answer.text')}</p>
              <br />
              <ul className="ul-styled">
                <li>{t('home.faqs.1.answer.list.1')}</li>
                <li>{t('home.faqs.1.answer.list.2')}</li>
              </ul>
            </>
          }
        />
        <Accordion
          title={
            <div className="accordion__title-container">
              <svg className="accordion__caret">
                <use xlinkHref={`${Sprite}#caret-down`} />
              </svg>
              <div className="accordion__title">{t(`home.faqs.2.question`)}</div>
            </div>
          }
          panel={
            <>
              <p className="accordion__panel-text">
                {t('home.faqs.2.answer.text1')}{' '}
                <a href="https://elondoge.io/app/farm">
                  https://elondoge.io/app/farm
                </a>
              </p>
              <br />
              <p className="accordion__panel-text">{t('home.faqs.2.answer.text2')}</p>
              <br />
              <ol className="ol-styled">
                <li>{t('home.faqs.2.answer.list.1')}</li>
                <li>{t('home.faqs.2.answer.list.2')}</li>
                <li>{t('home.faqs.2.answer.list.3')}</li>
              </ol>
            </>
          }
        />
        <Accordion
          title={
            <div className="accordion__title-container">
              <svg className="accordion__caret">
                <use xlinkHref={`${Sprite}#caret-down`} />
              </svg>
              <div className="accordion__title">{t(`home.faqs.3.question`)}</div>
            </div>
          }
          panel={
            <>
              <p className="accordion__panel-text">{t('home.faqs.3.answer.text1')}</p>
              <br />
              <p className="accordion__panel-text">{t('home.faqs.3.answer.text2')}</p>
              <br />
              <p className="accordion__panel-text">{t('home.faqs.3.answer.text3')}</p>
            </>
          }
        />
        <Accordion
          title={
            <div className="accordion__title-container">
              <svg className="accordion__caret">
                <use xlinkHref={`${Sprite}#caret-down`} />
              </svg>
              <div className="accordion__title">{t(`home.faqs.4.question`)}</div>
            </div>
          }
          panel={
            <>
              <p className="accordion__panel-text">{t('home.faqs.4.answer.text')}</p>
              <br />
              <ul className="ul-styled">
                <li>{t('home.faqs.4.answer.list.1')}</li>
                <li>{t('home.faqs.4.answer.list.2')}</li>
              </ul>
            </>
          }
        />
        <Accordion
          title={
            <div className="accordion__title-container">
              <svg className="accordion__caret">
                <use xlinkHref={`${Sprite}#caret-down`} />
              </svg>
              <div className="accordion__title">{t(`home.faqs.5.question`)}</div>
            </div>
          }
          panel={
            <>
              <p className="accordion__panel-text">
                {t('home.faqs.5.answer.text')}{' '}
                <a href="https://www.coingecko.com/en/coins/elon-doge-token">
                  https://www.coingecko.com/en/coins/elon-doge-token
                </a>
              </p>
            </>
          }
        />
        <Accordion
          title={
            <div className="accordion__title-container">
              <svg className="accordion__caret">
                <use xlinkHref={`${Sprite}#caret-down`} />
              </svg>
              <div className="accordion__title">{t(`home.faqs.6.question`)}</div>
            </div>
          }
          panel={
            <>
              <p className="accordion__panel-text">{t('home.faqs.6.answer.text')}</p>
            </>
          }
        />
      </div>
    </div>
  );
}

export default withTranslation(['translation', 'common'])(Faq);
