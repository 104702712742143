import React, {
  createRef,
  useEffect,
  useState,
} from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap'
import BoostForm from './BoostForm';
import Delayed from '../common/Delayed';
import CommonToast from '../common/CommonToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useTokenBalance } from '@usedapp/core';
import { formatUnits } from '@ethersproject/units';
import {
  edogeSingle,
  edaoBNB,
  txErrorMessage,
  txSuccessMessage,
  validationMessage,
} from '../../utils/Constants';
import {
  approve,
  allowance,
  deposited,
  deposit,
  withdraw,
  claimNFTRewards,
} from '../../utils/EthWebProvider';
import '../../assets/css/components/farm/actionform.css';

const balanceRef = createRef();
const stakedRef = createRef();
const toastRef = createRef();
const boostFormRef = createRef();
const ActionForm = (props) => {
  const {
    account,
    tokenAddress,
    isBoosted,
  } = props;
  const [ isApproveLoading, setApproveLoading ] = useState(false);
  const [ isStakeLoading, setStakeLoading ] = useState(false);
  const [ isUnstakeLoading, setUnstakeLoading ] = useState(false);
  const [ isHarvestLoading, setHarvestLoading ] = useState(false);
  const [ isBoostingLoading, setBoostingLoading ] = useState(false);
  const [ isClaimLoading, setClaimLoading ] = useState(false);
  const [ isApproved, setApproved ] = useState(false);
  const [ stakeValue, setStakeValue ] = useState(0);
  const [ unstakeValue, setUnstakeValue ] = useState(0);
  const [ toastVariant, setToastVariant ] = useState('success');
  const [ successMessage, setSuccessMessage ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');

  const tokenBalance = useTokenBalance(tokenAddress, account);
  
  useEffect(() => {
    (async () => {
      const walletAllowance = await allowance(tokenAddress);

      setApproved(walletAllowance > 0);
    })();
  }, [ tokenAddress, account ]);
  
  if (tokenAddress === edogeSingle) {
    setTimeout(async () => {
      if (balanceRef.current && tokenBalance) {
        balanceRef.current.textContent = formatUnits(tokenBalance, 9);
      }

      const staked = await deposited('edoge');
      
      if (stakedRef.current) {
        stakedRef.current.textContent = staked;
      }
    }, 1000);
  } else if (tokenAddress === edaoBNB) {
    setTimeout(async () => {
      if (balanceRef.current && tokenBalance) {
        balanceRef.current.textContent = formatUnits(tokenBalance, 18);
      }

      const staked = await deposited('edaoBNB');
      
      if (stakedRef.current) {
        stakedRef.current.textContent = staked;
      }
    }, 1000);
  } else {
    setTimeout(async () => {
      if (balanceRef.current && tokenBalance) {
        balanceRef.current.textContent = formatUnits(tokenBalance, 18);
      }

      const staked = await deposited('edogeEdao');
      
      if (stakedRef.current) {
        stakedRef.current.textContent = staked;
      }
    }, 1000);
  }

  const openSuccessToast = (txHash = null) => {
    setToastVariant('success');
    if (txHash) {
      setSuccessMessage(txSuccessMessage(txHash));
    } else {
      setSuccessMessage('Transaction successful!');
    }
    toastRef.current.handleOpen();
  };

  const openDangerToast = (message = null) => {
    setToastVariant('danger');
    setErrorMessage(message ? message : txErrorMessage);
    toastRef.current.handleOpen();
  }

  const approveContract = async () => {
    try {
      setApproveLoading(true);
      const { contractInstance: { transactionHash } } = await approve(tokenAddress);
      openSuccessToast(transactionHash);

      setTimeout(() => {
        setApproveLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
      openDangerToast();

      setTimeout(() => {
        setApproveLoading(false);
      }, 1000);
    }
  };

  const setStakeInputToMax = async () => {
    if (balanceRef.current) {
      const balance = balanceRef.current.textContent;
      setStakeValue(balance);
    }
  };

  const setUnstakeInputToMax = async () => {
    if (stakedRef.current) {
      const balance = stakedRef.current.textContent;
      setUnstakeValue(balance);
    }
  };

  const stakeContract = async () => {
    if (stakeValue === 0 || !stakeValue) {
      openDangerToast(validationMessage);
      return;
    }
    
    try {
      if (tokenAddress === edogeSingle) {
        setStakeLoading(true);
        const {
          totalDeposited,
          contractInstance: {
            transactionHash
          }
        } = await deposit(stakeValue, tokenAddress, 0);
        setStakeLoading(false);
        stakedRef.current.textContent = totalDeposited;

        openSuccessToast(transactionHash);

        setStakeValue(0);
        setTimeout(() => {
          setStakeLoading(false);
        }, 1000);
      } else {
        let pid = 0;
        if (tokenAddress === edaoBNB) {
          pid = 1;
        }

        setStakeLoading(true);
        const {
          totalDeposited,
          contractInstance: {
            transactionHash
          }
        } = await deposit(stakeValue, tokenAddress, pid);
        setStakeLoading(false);
        stakedRef.current.textContent = totalDeposited;

        openSuccessToast(transactionHash);

        setStakeValue(0);
        setTimeout(() => {
          setStakeLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      openDangerToast(error.message);

      setTimeout(() => {
        setStakeLoading(false);
      }, 1000);
    }
  };

  const unstakeContract = async () => {
    if (unstakeValue === 0 || !unstakeValue) {
      openDangerToast(validationMessage);
      return;
    }

    try {
      if (tokenAddress === edogeSingle) {
        setUnstakeLoading(true);
        const {
          totalDeposited,
          contractInstance: {
            transactionHash
          }
        } = await withdraw(unstakeValue, tokenAddress, 0);
        setUnstakeLoading(false);
        stakedRef.current.textContent = totalDeposited;

        openSuccessToast(transactionHash);

        setUnstakeValue(0);
        setTimeout(() => {
          setUnstakeLoading(false);
        }, 1000);
      } else {
        let pid = 0;
        if (tokenAddress === edaoBNB) {
          pid = 1;
        }

        setUnstakeLoading(true);
        const {
          totalDeposited,
          contractInstance: {
            transactionHash
          }
        } = await withdraw(unstakeValue, tokenAddress, pid);
        setUnstakeLoading(false);
        stakedRef.current.textContent = totalDeposited;

        openSuccessToast(transactionHash);

        setUnstakeValue(0);
        setTimeout(() => {
          setUnstakeLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      openDangerToast(error.message);

      setTimeout(() => {
        setUnstakeLoading(false);
      }, 1000);
    }
  };

  const harvestContract = async() => {
    try {
      if (tokenAddress === edogeSingle) {
        setHarvestLoading(true);
        const { contractInstance: { transactionHash }} =
          await withdraw(0, tokenAddress, 0);
        setHarvestLoading(false);

        openSuccessToast(transactionHash);

        setTimeout(() => {
          setHarvestLoading(false);
        }, 1000);
      } else {
        let pid = 0;
        if (tokenAddress === edaoBNB) {
          pid = 1;
        }

        setHarvestLoading(true);
        const { contractInstance: { transactionHash }} =
          await withdraw(0, tokenAddress, pid);
        setHarvestLoading(false);

        openSuccessToast(transactionHash);

        setTimeout(() => {
          setHarvestLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      openDangerToast(error.message);

      setTimeout(() => {
        setHarvestLoading(false);
      }, 1000);
    }
  };

  const openBoostModal = async () => {
    boostFormRef.current.handleOpen();
  };

  const claimBoostRewards = async () => {
    try {
      if (tokenAddress === edogeSingle) {
        setClaimLoading(true);
        const { contractInstance: { transactionHash }} =
          await claimNFTRewards(tokenAddress, 0);
          setClaimLoading(false);

        openSuccessToast(transactionHash);

        setTimeout(() => {
          setClaimLoading(false);
        }, 1000);
      } else {
        let pid = 0;
        if (tokenAddress === edaoBNB) {
          pid = 1;
        }

        setClaimLoading(true);
        const { contractInstance: { transactionHash }} =
          await claimNFTRewards(tokenAddress, pid);
        setClaimLoading(false);

        openSuccessToast(transactionHash);

        setTimeout(() => {
          setClaimLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      openDangerToast(error.message);

      setTimeout(() => {
        setClaimLoading(false);
      }, 1000);
    }
  };

  return (
    <Row className="tw-mt-3 md:tw-mt-4 lg:tw-mt-6 container elondoge-table__form tw-mx-0">
      <CommonToast 
        toastVariant={toastVariant} 
        ref={toastRef}
        successMessage={successMessage}
        dangerMessage={errorMessage}
      />
      <BoostForm
        ref={boostFormRef}
        isBoostingLoading={isBoostingLoading}
        setBoostingLoading={setBoostingLoading}
        tokenAddress={tokenAddress}
        openSuccessToast={openSuccessToast}
        openDangerToast={openDangerToast}
      />
      {isApproved ? (
        <Delayed>
          <Row className="tw-mx-0 tw-px-0 tw-flex-col md:tw-flex-row">
            <Col className="tw-mb-4 md:tw-mb-0 tw-px-0 md:tw-px-3">
              <Form.Group className="mb-3">
                <Form.Label>
                  <small>Wallet balance: <span ref={balanceRef}>0</span></small>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="lg"
                    type="number"
                    placeholder="0.0"
                    value={stakeValue}
                    onChange={(e) => setStakeValue(e.target.value)}
                  />
                  <button
                    className="btn elondoge-table__input-btn"
                    onClick={setStakeInputToMax}
                  >
                    Max
                  </button>
                </InputGroup>
              </Form.Group>
              <Button
                bsPrefix="elondoge-btn"
                size="lg"
                disabled={isStakeLoading}
                className="tw-w-full"
                onClick={async() => stakeContract()}
              >
                {isStakeLoading ? (
                  <p className="tw-animate-spin h-5 w-5 mr-3">
                    <FontAwesomeIcon icon={faCircleNotch} />
                  </p>
                ): 'Stake'}
              </Button>
            </Col>
            <Col className="tw-mb-4 md:tw-mb-0 tw-px-0 md:tw-px-3">
              <Form.Group className="mb-3">
                <Form.Label>
                  <small>Staked: <span ref={stakedRef}>0</span></small>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="lg"
                    type="number"
                    placeholder="0.0"
                    value={unstakeValue}
                    onChange={(e) => setUnstakeValue(e.target.value)}
                  />
                  <button
                    className="btn elondoge-table__input-btn"
                    onClick={setUnstakeInputToMax}
                  >
                    Max
                  </button>
                </InputGroup>
              </Form.Group>
              <Button
                bsPrefix="elondoge-btn"
                size="lg"
                disabled={isUnstakeLoading}
                className="tw-w-full"
                onClick={async() => unstakeContract()}
              >
                {isUnstakeLoading ? (
                  <p className="tw-animate-spin h-5 w-5 mr-3">
                    <FontAwesomeIcon icon={faCircleNotch} />
                  </p>
                ): 'Unstake'}
              </Button>
            </Col>
          </Row>
          <Row className="tw-mt-4 tw-mx-0 tw-px-0 tw-flex-col md:tw-flex-row">
            <Col className="tw-block md:tw-grid tw-px-0 md:tw-px-3 tw-mb-4 md:tw-mb-0">
              {!isBoosted ? (
                <Button
                  bsPrefix="elondoge-btn"
                  variant="outline-primary"
                  size="lg"
                  onClick={async() => openBoostModal()}
                  className="tw-w-full"
                >
                  {isBoostingLoading ? (
                    <p className="tw-animate-spin h-5 w-5 mr-3">
                      <FontAwesomeIcon icon={faCircleNotch} />
                    </p>
                  ): 'Equip NFT'}
                </Button>
              ) : ''}
            </Col>
            <Col className="tw-block md:tw-grid tw-px-0 md:tw-px-3">
              <Button
                bsPrefix="elondoge-btn"
                variant="outline-primary"
                size="lg"
                disabled={isHarvestLoading}
                className="tw-w-full"
                onClick={async() => harvestContract()}
              >
                {isHarvestLoading ? (
                  <p className="tw-animate-spin h-5 w-5 mr-3">
                    <FontAwesomeIcon icon={faCircleNotch} />
                  </p>
                ): 'Harvest'}
              </Button>
            </Col>
          </Row>
          {!isBoosted ? (
            <Row className="tw-mt-4 tw-mx-0 tw-px-0 tw-flex-col md:tw-flex-row">
              <Col className="tw-block md:tw-grid tw-px-0 md:tw-px-3 tw-mb-4 md:tw-mb-0">
                <Button
                  bsPrefix="elondoge-btn"
                  variant="outline-primary"
                  size="lg"
                  disabled={isClaimLoading}
                  onClick={async() => claimBoostRewards()}
                  className="tw-w-full"
                >
                  {isClaimLoading ? (
                    <p className="tw-animate-spin h-5 w-5 mr-3">
                      <FontAwesomeIcon icon={faCircleNotch} />
                    </p>
                  ): 'Claim Boost Rewards'}
                </Button>
              </Col>
              <Col></Col>
            </Row>
          ) : ''}
        </Delayed>
      ) : (
        <Delayed>
          <Row className="tw-mx-0 tw-px-0">
            <Col className="tw-block md:tw-grid tw-px-0 md:tw-px-3">
              <Button
                size="lg"
                disabled={isApproveLoading}
                bsPrefix="elondoge-btn"
                onClick={approveContract}
                className="tw-w-full"
              >
                {isApproveLoading ? (
                  <p className="tw-animate-spin h-5 w-5 mr-3">
                    <FontAwesomeIcon icon={faCircleNotch} />
                  </p>
                ): 'Approve'}
              </Button>
            </Col>
            <Col className="tw-hidden md:tw-grid" />
          </Row>
        </Delayed>
      )}
    </Row>
  );
}

export default ActionForm;
