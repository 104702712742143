import Button from '../../../components/common/Button';
import ElonFarmImg from '../../../assets/images/elonfarm.png';
import E10nNftFoundyImg from '../../../assets/images/e10n-nft-foundry.png';
import AuctionStationImg from '../../../assets/images/auction-station.png';
import ElonFuelImg from '../../../assets/images/elonfuel.png';
import ElonSwapImg from '../../../assets/images/elonswap.png';
import ElonFundImg from '../../../assets/images/elonfund.png';
import ElonPlayImg from '../../../assets/images/elonplay.png';
import { withTranslation } from 'react-i18next';

function Universe({ t }) {
  const items = [
    {
      title: t('home.universe.elonfarm.title'),
      description: t('home.universe.elonfarm.copy'),
      btnLabel: t('common:launchApp'),
      img: ElonFarmImg,
    },
    {
      title: t('home.universe.e10n.title'),
      description: t('home.universe.e10n.copy'),
      btnLabel: t('common:browseNfts'),
      img: E10nNftFoundyImg,
    },
    {
      title: t('home.universe.auction.title'),
      description: t('home.universe.auction.copy'),
      btnLabel: t('common:burnFunction'),
      img: AuctionStationImg,
    },
    {
      title: t('home.universe.elonfuel.title'),
      description: t('home.universe.elonfuel.copy'),
      btnLabel: t('common:learnMore'),
      img: ElonFuelImg,
    },
    {
      title: t('home.universe.elonswap.title'),
      description: t('home.universe.elonswap.copy'),
      btnLabel: t('common:seePreview'),
      img: ElonSwapImg,
    },
    {
      title: t('home.universe.elonfund.title'),
      description: t('home.universe.elonfund.copy'),
      btnLabel: t('common:seeStats'),
      img: ElonFundImg,
    },
    {
      title: t('home.universe.elonplay.title'),
      description: t('home.universe.elonplay.copy'),
      btnLabel: t('common:seePreview'),
      img: ElonPlayImg,
    },
  ];

  return (
    <div className="tw-my-10 lg:tw-my-20 xl:tw-my-32">
      <div className="tw-w-11/12 xl:tw-w-10/12 tw-mx-auto">
        <div className="tw-text-center tw-space-y-6 lg:tw-space-y-8 xl:tw-space-y-10">
          <h2 className="main-heading">{t('home.universe.title')}</h2>
          <p>{t('home.universe.copy')}</p>
        </div>
        <div className="tw-mt-10 lg:tw-mt-16 xl:tw-mt-28 tw-space-y-10 lg:tw-space-y-16 xl:tw-space-y-28">
          {items.map((item, i) => (
            <div
              key={i}
              className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-5"
            >
              <div
                className={`tw-w-full md:tw-w-5/12 lg:tw-w-5/12 tw-text-center xl:tw-text-left tw-space-y-10 tw-order-last ${
                  i % 2 ? 'md:tw-order-last' : 'md:tw-order-first'
                }`}
              >
                <h3 className="heading">{item.title}</h3>
                <p>{item.description}</p>
                <Button color="gradient" size="xl">
                  {item.btnLabel}
                </Button>
              </div>
              <div className="tw-w-full md:tw-w-6/12 lg:tw-w-5/12">
                <img className="tw-w-full" src={item.img} alt={item.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withTranslation(['translation', 'common'])(Universe);
