import React, { createRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import AnalyticsModal from './AnalyticsModal';

const Footer = () => {
  
  const analytics = createRef();

  const openAnalyticsModal = () => {
    analytics.current.handleOpen();
  };

  return (
    <>
      <footer>
        <Button variant="dark" bsPrefix="elondoge-btn" onClick={openAnalyticsModal}>
          Analytics
          &nbsp;<FontAwesomeIcon icon={faChartLine} />
        </Button>
      </footer>
      <AnalyticsModal ref={analytics}/>
    </>
  );
}

export default Footer;