import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { DAppProvider, ChainId } from '@usedapp/core';
import App from './App';
import reportWebVitals from './utils/ReportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { apiEndpoint } from './utils/Constants';
import Icon from './assets/images/elondoge-logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/fonts.css';
import './assets/css/tailwind.css';
import './assets/css/index.css';
import './assets/css/common.css';
import './assets/css/components/global.css';
import './i18n';

const config = {
  readOnlyChain: ChainId.BSC,
  readOnlyUrls: {
    [ChainId.BSC]: apiEndpoint,
  },
};

let webvitals = [];

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="fixed inset-0 bg-black flex items-center justify-center">
          <img src={Icon} alt="Loader" />
        </div>
      }
    >
      <BrowserRouter basename={'/app'}>
        <DAppProvider config={{ config }}>
          <App />
        </DAppProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

const sendAnalytics = ({entries, name, id, value, delta}) => {
  const webVitalsIndex = webvitals.findIndex((vital) => vital.name === name )
  if(webVitalsIndex !== -1)
    webvitals[webVitalsIndex] = {entries, name, id, value, delta}
  else
    webvitals = [ ...webvitals, {entries, name, id, value, delta}]

  sessionStorage.setItem('webvitals', JSON.stringify(webvitals))
}

reportWebVitals(sendAnalytics);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
