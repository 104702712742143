import React, {
  useEffect,
  useState,
  createRef,
} from 'react';
import {
  // Form,
  // InputGroup,
  Badge,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useBlockNumber } from '@usedapp/core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { calculateAllApys, calculateRewards } from '../../utils/APIWebProvider';
import { boostInfo } from '../../utils/EthWebProvider';
import {
  edogeSingle,
  edaoBNB,
  edogeEdao,
  listItemInfoMessage,
} from '../../utils/Constants';
import ActionForm from './ActionForm';
import OldFarms from './OldFarms';
import CommonToast from '../common/CommonToast';
import edoge from '../../assets/img/edoge.svg';
import edao from '../../assets/img/edao.svg';
import edaoSquare from '../../assets/img/edao-square.svg';
import binance from '../../assets/img/binance.svg';
import '../../assets/css/components/farm/list.css';

const edogeTVLRef = createRef();
const edaoBnbTVLRef = createRef();
const edogeEdaoTVLRef = createRef();
const apyEdogeRef = createRef();
const apyEdaoBnbRef = createRef();
const apyEdogeEdaoRef = createRef();

const edogeSingleRewardsRef = createRef();
const edaoBnbRewardsRef = createRef();
const edogeEdaoRewardsRef = createRef();

const toastRef = createRef();
const List = (props) => {
  const { account, className, tokenAddressList, filters } = props;
  const [ class1, setClass1 ] = useState('');
  const [ class2, setClass2 ] = useState('');
  const [ class3, setClass3 ] = useState('');
  const [ rowClass1, setRowClass1 ] = useState('');
  const [ rowClass2, setRowClass2 ] = useState('');
  const [ rowClass3, setRowClass3 ] = useState('');
  const [ showForm1, setShowForm1 ] = useState(false);
  const [ showForm2, setShowForm2 ] = useState(false);
  const [ showForm3, setShowForm3 ] = useState(false);
  const [ edogeBoosted, setEdogeBoosted ] = useState(false);
  const [ edaoBoosted, setEdaoBoosted ] = useState(false);
  const [ edogeEdaoBoosted, setEdogeEdaoBoosted ] = useState(false);

  const currentBlock = useBlockNumber();

  const [showAllFarm, setShowAllFarm] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const {
          edogeTVL,
          edaoBnbTVL,
          edogeEdaoTVL,
          apyEdoge,
          apyEdaoBnb,
          apyEdogeEdao,
        } = await calculateAllApys();
  
        edogeTVLRef.current.textContent = `$${edogeTVL}`;
        edaoBnbTVLRef.current.textContent = `$${edaoBnbTVL}`;
        edogeEdaoTVLRef.current.textContent = `$${edogeEdaoTVL}`;
        apyEdogeRef.current.textContent = `${apyEdoge}%`;
        apyEdaoBnbRef.current.textContent = `${apyEdaoBnb}%`;
        apyEdogeEdaoRef.current.textContent = `${apyEdogeEdao}%`;
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (account) {
        try {
          const {
            rewards1,
            rewards2,
            rewards3,
          } = await calculateRewards(account);
  
          edogeSingleRewardsRef.current.textContent = `${rewards1} EDAO`;
          edaoBnbRewardsRef.current.textContent = `${rewards2} EDAO`;
          edogeEdaoRewardsRef.current.textContent = `${rewards3} EDAO`;

          const { boostEndBlock: edogeEndBlock } = await boostInfo(edogeSingle);
          setEdogeBoosted(currentBlock <= edogeEndBlock);

          const { boostEndBlock: edaoEndBlock } = await boostInfo(edaoBNB);
          setEdaoBoosted(currentBlock <= edaoEndBlock);
          setEdogeEdaoBoosted(currentBlock <= edaoEndBlock);
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [ account, currentBlock ]);

  useEffect(() => {
    if(account) {
      if(filters[1].isEnable) {
          if( tokenAddressList[0].isFarm )
            setRowClass1('')
          else
            setRowClass1('tw-hidden')

          if( tokenAddressList[1].isFarm )
            setRowClass2('')
          else
            setRowClass2('tw-hidden')
            
          if( tokenAddressList[2].isFarm )
            setRowClass3('')
          else
            setRowClass3('tw-hidden')

          setShowAllFarm(false)
      } else {
        setRowClass1('')
        setRowClass2('')
        setRowClass3('')
        setShowAllFarm(true)
      }
    }

  }, [account, filters, tokenAddressList])

  const showForm = (formNumber) => {
    if (account) {
      if (formNumber === 1) {
        if (!showForm1) {
          setClass1('elondoge-table__body--active');
        } else {
          setClass1('');
        }
  
        setShowForm1(!showForm1);

        setShowForm2(false);
        setClass2('');
        setShowForm3(false);
        setClass3('');
      } else if (formNumber === 2) {
        if (!showForm2) {
          setClass2('elondoge-table__body--active');
        } else {
          setClass2('');
        }
  
        setShowForm2(!showForm2);

        setShowForm1(false);
        setClass1('');
        setShowForm3(false);
        setClass3('');
      } else if (formNumber === 3) {
        if (!showForm3) {
          setClass3('elondoge-table__body--active');
        } else {
          setClass3('');
        }
  
        setShowForm3(!showForm3);

        setShowForm1(false);
        setClass1('');
        setShowForm2(false);
        setClass2('');
      }
    } else {
      toastRef.current.handleOpen();
    }
  }

  return (
    <section className={`elondoge-list ${className} tw-px-4`}>
      {/* Start of Farm 1 */}
      {/* <Row>
        <InputGroup className="tw-w-full">
          <InputGroup.Text id="search-prepend" className="elondoge-list__search-prepend">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            size="lg"
            type="text"
            placeholder="Search by name, symbol, address"
            aria-describedby="search-prepend"
            className="elondoge-list__search"
          />
        </InputGroup>
      </Row> */}
      <Row className={`tw-mt-6 container tw-mx-0 tw-px-0 ${rowClass1}`}>
        <Row className="tw-mx-0 tw-px-0">
          <h5 className="tw-w-auto tw-flex-none text-white tw-pr-5">Farm 1</h5>
          <hr className="tw-w-auto tw-flex-1 text-white mt-2" />
        </Row>
      </Row>
      <Container className={`${rowClass1} tw-px-0`} fluid>
        <Row className="tw-mt-6 container elondoge-table__header tw-hidden lg:tw-flex">
          <Col xs="4">
            Pool
          </Col>
          <Col xs="2">
            TVL
          </Col>
          <Col xs="5">
            Rewards
          </Col>
          <Col xs="1">
            APY
          </Col>
        </Row>
        <Row
          className={`tw-mt-6 container elondoge-table__body tw-cursor-pointer tw-mx-0 tw-py-6 lg:tw-py-0 ${class1}`} 
          onClick={() => showForm(1)}
        >
          <Col xs="12" lg="4" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <Row className="tw-w-1/3 md:tw-w-1/2 tw-block lg:tw-hidden">
              <Col xs="12">
                Pool
              </Col>
            </Row>
            <Row className="tw-mx-0 tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto">
              <Col xs="auto" lg="3">
                <img src={edoge} alt="edoge icon" width="44" height="44" />
              </Col>
              <Col xs="auto" lg="9">
                <Row className={edogeBoosted ? '' : 'mt-2'}>
                  <span className="fs-6 fw-bold">EDOGE</span>
                </Row>
                {edogeBoosted ? (
                  <Row>
                    <Col xs="6">
                      <Badge bg="warning" text="dark" className="tw-animate-bounce">
                        Boosted
                      </Badge>
                    </Col>
                  </Row>
                ) : ''}
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="2" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">TVL</div>
            <span className="elondoge-table__column--numbers tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0" ref={edogeTVLRef}>$0.00</span>
          </Col>
          <Col xs="12" lg="5" className="tw-flex lg:tw-block tw-items-baseline md:tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">Rewards</div>
            <div className="tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0">
              <img src={edaoSquare} alt="edao icon" width="44" height="44" className="me-3 tw-mb-3 md:tw-mb-0" />
              <span className="elondoge-table__column--numbers" ref={edogeSingleRewardsRef}>0.00 EDAO</span>
            </div>
          </Col>
          <Col xs="12" lg="1" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">APY</div>
            <span className="elondoge-table__column--numbers tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0" ref={apyEdogeRef}>0%</span>
          </Col>
        </Row>
        {showForm1 ? (
          <ActionForm
            isBoosted={edogeBoosted}
            account={account}
            tokenAddress={edogeSingle}
          />
        ) : ''}
      </Container>
      {/* Start of Farm 2 */}
      <Row className={`tw-mt-6 container tw-mx-0 tw-px-0 ${rowClass2}`}>
        <Row className="tw-mx-0 tw-px-0">
          <h5 className="tw-w-auto tw-flex-none text-white tw-pr-5">Farm 2</h5>
          <hr className="tw-w-auto tw-flex-1 text-white mt-2" />
        </Row>
      </Row>
      <Container className={`${rowClass2} tw-px-0`} fluid>
        <Row className="tw-mt-6 container elondoge-table__header tw-hidden lg:tw-flex">
          <Col xs="4">
            Pool
          </Col>
          <Col xs="2">
            TVL
          </Col>
          <Col xs="5">
            Rewards
          </Col>
          <Col xs="1">
            APY
          </Col>
        </Row>
        <Row
          className={`tw-mt-6 container elondoge-table__body tw-cursor-pointer tw-mx-0 tw-py-6 lg:tw-py-0 ${class2}`}
          onClick={() => showForm(2)}
        >
          <Col xs="12" lg="4" className="tw-flex lg:tw-block tw-items-baseline md:tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <Row className="tw-w-1/3 md:tw-w-1/2 tw-block lg:tw-hidden">
              <Col xs="12">
                Pool
              </Col>
            </Row>
            <Row className="tw-mx-0 tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto">
              <Col xs="auto" lg="6">
                <img src={edao} alt="edao icon" width="44" height="44" className="me-3" />
                <img src={binance} alt="binance icon" width="44" height="44" />
              </Col>
              <Col xs="auto" lg="6" className="tw-mt-4 md:tw-mt-0">
                <Row className={edaoBoosted ? '' : 'mt-2'}>
                  <span className="fs-6 fw-bold">EDAO</span>
                </Row>
                {edaoBoosted ? (
                  <Row>
                    <Col xs="6">
                      <Badge bg="warning" text="dark" className="tw-animate-bounce">
                        Boosted
                      </Badge>
                    </Col>
                  </Row>
                ) : ''}
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="2" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">TVL</div>
            <span className="elondoge-table__column--numbers tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0" ref={edaoBnbTVLRef}>$0.00</span>
          </Col>
          <Col xs="12" lg="5" className="tw-flex lg:tw-block tw-items-baseline md:tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">Rewards</div>
            <div className="tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0">
              <img src={edaoSquare} alt="edao icon" width="44" height="44" className="me-3 tw-mb-3 md:tw-mb-0" />
              <span className="elondoge-table__column--numbers" ref={edaoBnbRewardsRef}>0.00 EDAO</span>
            </div>
          </Col>
          <Col xs="12" lg="1" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">APY</div>
            <span className="elondoge-table__column--numbers tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0" ref={apyEdaoBnbRef}>0%</span>
          </Col>
        </Row>
        {showForm2 ? (
          <ActionForm
            isBoosted={edaoBoosted}
            account={account}
            tokenAddress={edaoBNB}
          />
        ) : ''}
      </Container>
      {/* Start of Farm 3 */}
      <Row className={`tw-mt-6 container tw-mx-0 tw-px-0 ${rowClass3}`}>
        <Row className="tw-mx-0 tw-px-0">
          <h5 className="tw-w-auto tw-flex-none text-white tw-pr-5">Farm 3</h5>
          <hr className="tw-w-auto tw-flex-1 text-white mt-2" />
        </Row>
      </Row>
      <Container className={`${rowClass3} tw-px-0`} fluid>
        <Row className="tw-mt-6 container elondoge-table__header tw-hidden lg:tw-flex">
          <Col xs="4">
            Pool
          </Col>
          <Col xs="2">
            TVL
          </Col>
          <Col xs="5">
            Rewards
          </Col>
          <Col xs="1">
            APY
          </Col>
        </Row>
        <Row
          className={`tw-mt-6 container elondoge-table__body tw-cursor-pointer tw-mx-0 tw-py-6 lg:tw-py-0 ${class3}`}
          onClick={() => showForm(3)}
        >
          <Col xs="12" lg="4" className="tw-flex lg:tw-block tw-items-baseline md:tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <Row className="tw-w-1/3 md:tw-w-1/2 tw-block lg:tw-hidden">
              <Col xs="12">
                Pool
              </Col>
            </Row>
            <Row className="tw-mx-0 tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto">
              <Col xs="auto" lg="6">
                <img src={edoge} alt="edoge icon" width="44" height="44" className="me-3" />
                <img src={edao} alt="edao icon" width="44" height="44" />
              </Col>
              <Col xs="auto" lg="6" className="tw-mt-4 md:tw-mt-0">
                <Row className={edogeEdaoBoosted ? '' : 'mt-2'}>
                  <span className="fs-6 fw-bold">EDOGE EDAO</span>
                </Row>
                {edogeEdaoBoosted ? (
                  <Row>
                    <Col xs="6">
                      <Badge bg="warning" text="dark" className="tw-animate-bounce">
                        Boosted
                      </Badge>
                    </Col>
                  </Row>
                ) : ''}
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="2" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">TVL</div>
            <span className="elondoge-table__column--numbers tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0" ref={edogeEdaoTVLRef}>$0.00</span>
          </Col>
          <Col xs="12" lg="5" className="tw-flex lg:tw-block tw-items-baseline md:tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0 tw-mb-4 lg:tw-mb-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">Rewards</div>
            <div className="tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0">
              <img src={edaoSquare} alt="edao icon" width="44" height="44" className="me-3 tw-mb-3 md:tw-mb-0" />
              <span className="elondoge-table__column--numbers" ref={edogeEdaoRewardsRef}>0.00 EDAO</span>
            </div>
          </Col>
          <Col xs="12" lg="1" className="tw-flex lg:tw-block tw-items-center tw-justify-between tw-px-0 md:tw-px-2 lg:tw-px-0">
            <div className="tw-block lg:tw-hidden tw-w-1/3 md:tw-w-1/2">APY</div>
            <span className="elondoge-table__column--numbers tw-w-2/3 md:tw-w-1/2 lg:tw-w-auto tw-pl-3 lg:tw-pl-0" ref={apyEdogeEdaoRef}>508.64%</span>
          </Col>
        </Row>
        {showForm3 ? (
          <ActionForm
            isBoosted={edogeEdaoBoosted}
            account={account}
            tokenAddress={edogeEdao}
          />
        ) : ''}
      </Container>
      {(showAllFarm) && (<OldFarms/>)}
      <CommonToast
        toastVariant="dark"
        ref={toastRef}
        infoMessage={listItemInfoMessage}
      />
    </section>
  );
}

export default List;
