import React, {
  useState,
  useEffect,
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Delayed = ({ children, waitBeforeShow = 1000 }) => {
  const [ isShow, setIsShow ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShow(true);
    }, waitBeforeShow);
  }, [ waitBeforeShow ])

  return isShow ? children : (
    <Row>
      <Col xs="12" className="d-flex justify-content-center">
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="tw-animate-spin"
          size="lg"
        />
      </Col>
    </Row>
  );
};

export default Delayed;
