export const edogeSingle = process.env.REACT_APP_EDOGE_SINGLE;
export const edogeBNB = process.env.REACT_APP_EDOGE_BNB;
export const edaoBNB = process.env.REACT_APP_EDAO_BNB;
export const edogeEdao = process.env.REACT_APP_EDOGE_EDAO;
export const edogeFarmContract = process.env.REACT_APP_EDOGE_FARM_CONTRACT;
export const edaoFarmContract = process.env.REACT_APP_EDAO_FARM_CONTRACT
export const nftContract = process.env.REACT_APP_NFT_CONTRACT;
export const oldFarmContract = process.env.REACT_APP_OLD_FARM_CONTRACT;
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
export const apiPancakeEdoge = process.env.REACT_APP_PANCAKE_API_EDOGE;
export const apiPancakeEdao = process.env.REACT_APP_PANCAKE_API_EDAO;
export const apiPanckaeBNB = process.env.REACT_APP_PANCAKE_API_BNB;
export const metamaskErrorMessage = 'Please install MetaMask on your browser.';
export const txErrorMessage = 'Transaction error! Please check your wallet.';
export const connectSuccessMessage = 'Successfully connected wallet.';
export const connectErrorMessage = 'Wallet connection error!';
export const listItemInfoMessage = 'Connect to your Wallet to use Elondoge Features.';
export const txSuccessMessage = (txHash) => `Transaction ${txHash} was successful!`;
export const validationMessage = 'Zero (0) or empty values are not allowed';
export const legacyWithdrawalLabel = 'Legacy Withdrawal';
