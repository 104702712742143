import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/common/Button';
import ComingSoonModal from '../../../components/common/ComingSoonModal';
import Icon from '../../../assets/images/elondoge-logo.svg';
import Sprite from '../../../assets/images/sprite.svg';
import { withTranslation } from 'react-i18next';

const openHamburger = () => {
  const headerMenuMobile = document.getElementById('header-menu-mobile');
  document.body.setAttribute('style', 'overflow: hidden;');
  headerMenuMobile.classList.add('active');
};

const closeHamburger = () => {
  const headerMenuMobile = document.getElementById('header-menu-mobile');
  document.body.removeAttribute('style');
  headerMenuMobile.classList.remove('active');
};

function Header({ t, languages, selectedLanguage, handleLanguageChange }) {
  const [isShowModal, setShowModal] = useState(false);

  return (
    <>
      <header className="tw-absolute tw-z-20 tw-top-0 tw-w-full tw-text-white">
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-w-11/12 xl:tw-w-10/12 tw-mx-auto tw-py-6 lg:tw-py-10 xl:tw-py-14">
          <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-space-x-10">
            <Link to="/" className="tw-font-sf-ui-display tw-font-extrabold tw-text-4xl">
              ElonDoge
            </Link>
            <nav>
              <ul className="tw-hidden xl:tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-space-x-8">
                <li>
                  <Link to="/">{t('common:home')}</Link>
                </li>
                {/* <li>
                <Link to="/">{t('common:deck')}</Link>
              </li> */}
                <li>
                  <a
                    href="https://docsend.com/view/a55zk98x49rnjd62"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Woofpaper
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <nav>
            <div className="tw-hidden xl:tw-flex tw-flex tw-flex-wrap tw-items-center jtw-ustify-between tw-space-x-7">
              <Link to="/farm">
                <Button color="green">{t('common:launchFarm')}</Button>
              </Link>
              <Button color="blue" onClick={() => setShowModal(true)}>
                {t('common:launch')} NFTs
              </Button>
              <Button color="red" onClick={() => setShowModal(true)}>
                Elonfuel
              </Button>
            </div>
          </nav>
          <div className="tw-block xl:tw-hidden" onClick={openHamburger}>
            <svg className="tw-w-8 tw-h-8 tw-text-white">
              <use xlinkHref={`${Sprite}#bars`} />
            </svg>
          </div>
        </div>
      </header>

      <div id="header-menu-mobile">
        <div className="tw-relative tw-w-11/12 tw-mx-auto tw-py-6">
          <div onClick={closeHamburger} className="tw-absolute tw-top-5 tw-right-0">
            <svg className="tw-w-8 tw-h-8 tw-text-white">
              <use xlinkHref={`${Sprite}#times`} />
            </svg>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-3">
            <img className="tw-w-24" src={Icon} alt="" />
            <p className="tw-font-sf-ui-display tw-font-extrabold tw-text-4xl">
              ElonDoge
            </p>
          </div>
          <div className="tw-mt-10 tw-space-y-8">
            <nav>
              <ul className="tw-flex tw-flex-col tw-items-center tw-gap-y-5 tw-text-xl">
                <li>
                  <Link to="/">{t('common:home')}</Link>
                </li>
                {/* <li>
                <Link to="/">{t('common:deck')}</Link>
              </li> */}
                <li>
                  <a
                    href="https://docsend.com/view/a55zk98x49rnjd62"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Woofpaper
                  </a>
                </li>
              </ul>
            </nav>
            <nav>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-5 tw-pb-5">
                <a href="https://elondoge.io/app/farm">
                  <Button color="green">{t('common:launchFarm')}</Button>
                </a>
                <Button color="blue" onClick={() => setShowModal(true)}>
                  {t('common:launch')} NFTs
                </Button>
                <Button color="red" onClick={() => setShowModal(true)}>
                  Elonfuel
                </Button>
              </div>
            </nav>
            <div className="tw-flex tw-flex-col tw-items-center">
              <select
                className="tw-cursor-pointer tw-text-white tw-bg-transparent"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languages.map((_lang, i) => (
                  <option key={i} className="tw-text-black" value={_lang.code}>
                    {_lang.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="header-backdrop" onClick={closeHamburger}></div>
      <ComingSoonModal showModal={isShowModal} setShowModal={setShowModal} />
    </>
  );
}

export default withTranslation(['translation', 'common'])(memo(Header));
