import { memo } from 'react';

function Button({ children, className = '', color = false, size = 'md', ...rest }) {
  return (
    <button
      className={`btn-2 ${className} ${color ? `btn-2--${color}` : ''} ${size ? `btn-2--${size}` : ''}`}
      {...rest}
    >
      {children}
    </button>
  );
}

export default memo(Button);
