import BgTokenEdoge from '../../../assets/images/bg-token-edoge.png';
import BgTokenEswap from '../../../assets/images/bg-token-eswap.png';
import BgTokenEplay from '../../../assets/images/bg-token-eplay.png';
import Statistics from '../../../assets/images/statistics.svg';
import ChartEdoge from '../../../assets/images/chart-edoge.png';
import QuestionMarks from '../../../assets/images/question-marks.png';
import { withTranslation } from 'react-i18next';

const toggleTab = (tab) => {
  const tabpanes = document.querySelectorAll('.tabs__tabpane');
  const tabcontents = document.querySelectorAll('.tabs__content');
  const activeTabpane = document.querySelector(`[data-tabpane="${tab}"]`);
  const activeTabContent = document.querySelector(`[data-tabcontent="${tab}"]`);

  tabpanes.forEach((tabpane) =>
    tabpane.classList.remove('tabs__tabpane--active')
  );
  activeTabpane.classList.add('tabs__tabpane--active');

  tabcontents.forEach((tabconent) =>
    tabconent.classList.remove('tabs__content--active')
  );
  activeTabContent.classList.add('tabs__content--active');
};

function Tokens({ t }) {
  return (
    <>
      <h2 className="main-heading tw-text-center">{t('common:tokens')}</h2>
      <div className="tabs tw-mt-16 lg:tw-mt-20 xl:tw-mt-24">
        <div className="tabs__wrapper tw-flex tw-flex-wrap tw-items-baseline tw-justify-around">
          <button
            className="tabs__tabpane tabs__tabpane--active"
            data-tabpane="edoge"
            onClick={() => toggleTab('edoge')}
          >
            <span className="tw-text-2xl lg:tw-text-3xl xl:tw-text-5.5xl tw-font-sf-ui-display tw-font-extrabold">
              $EDOGE
            </span>
          </button>
          <button
            className="tabs__tabpane"
            data-tabpane="edao"
            onClick={() => toggleTab('edao')}
          >
            <span className="tw-text-2xl lg:tw-text-3xl xl:tw-text-5.5xl tw-font-sf-ui-display tw-font-extrabold">
              $EDAO
            </span>
          </button>
          <button
            className="tabs__tabpane"
            data-tabpane="eswap"
            onClick={() => toggleTab('eswap')}
          >
            <span className="tw-text-2xl lg:tw-text-3xl xl:tw-text-5.5xl tw-font-sf-ui-display tw-font-extrabold">
              $ESWAP
            </span>
          </button>
          <button
            className="tabs__tabpane"
            data-tabpane="eplay"
            onClick={() => toggleTab('eplay')}
          >
            <span className="tw-text-2xl lg:tw-text-3xl xl:tw-text-5.5xl tw-font-sf-ui-display tw-font-extrabold">
              $EPLAY
            </span>
          </button>
        </div>

        <div className="tabs__content-wrapper tw-mt-4 lg:tw-mt-6 xl:tw-mt-12">
          <div
            className="tabs__content tabs__content--active"
            data-tabcontent="edoge"
          >
            <div className="tw-overflow-hidden tw-relative tw-rounded-4xl">
              <div
                className="tw-absolute tw-bg-center tw-bg-cover tw-bg-no-repeat tw-inset-0 tw-w-full"
                style={{ backgroundImage: `url(${BgTokenEdoge})` }}
              />
              <div className="tw-flex tw-flex-col tw-justify-center tw-mx-auto tw-py-6 lg:tw-py-20 xl:tw-py-40 tw-relative tw-gap-y-8 sm:tw-gap-y-16 tw-w-11/12 xl:tw-w-10/12">
                <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-7">
                  <img src={Statistics} alt="Statistics" />
                  <h3 className="heading">{t('common:statistics')}</h3>
                </div>
                <div className="tw-max-w-xl tw-space-y-6 sm:tw-space-y-8">
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:price')}</p>
                    <p className="tw-font-bold">$0.0000000035</p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:holders')}</p>
                    <p className="tw-font-bold">19,000+</p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:burntSupply')}</p>
                    <p className="tw-font-bold">
                      428 {t('common:trillion')} {t('common:or')} 42.8%{' '}
                      {t('common:ofSupply')}
                    </p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">
                      {t('common:twitterFollows')}
                    </p>
                    <p className="font-bold">51,000+</p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:telegramUsers')}</p>
                    <p className="tw-font-bold">45,000+</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-mt-12 lg:tw-mt-20 xl:tw-mt-40">
              <h3 className="heading">$EDOGE Chart</h3>
              <div className="tw-flex tw-flex-wrap tw-items-start tw-justify-around tw-mt-12 lg:tw-mt-16 xl:tw-mt-20">
                <div className="tw-w-full lg:tw-w-2/3 tw-flex tw-flex-col tw-items-center tw-space-y-10">
                  <img src={ChartEdoge} alt="Edoge Chart" />
                  <div className="tw-text-center tw-space-y-6">
                    <p className="tw-font-sf-ui-display tw-font-extrabold tw-text-5xl tw-break-all">
                      1,000,000,000,000,000
                    </p>
                    <p>{t('common:totalSupply')}</p>
                  </div>
                </div>
                <div className="tw-w-auto lg:tw-w-1/3">
                  <ul className="tw-flex tw-flex-wrap tw-flex-col tw-items-center tw-gap-x-2 tw-gap-y-10 tw-mt-12">
                    <li className="tw-flex tw-flex-wrap tw-items-start tw-justify-center tw-gap-x-5">
                      <div className="circle tw-bg-brown-1 tw-mt-1"></div>
                      <div className="tw-flex-1 tw-space-y-2">
                        <p className="tw-break-all tw-font-black tw-tracking-wide">
                          {t('common:initialTeamBurn')}
                        </p>
                        <p>300,000,000,000,000</p>
                      </div>
                    </li>
                    <li className="tw-flex tw-flex-wrap tw-items-start tw-justify-center tw-gap-x-5">
                      <div className="circle tw-bg-yellow-1 tw-mt-1"></div>
                      <div className="tw-flex-1 tw-space-y-2">
                        <p className="tw-break-all tw-font-black tw-tracking-wide">
                          {t('common:bnbIdoSupply')}
                        </p>
                        <p>200,000,000,000,000</p>
                      </div>
                    </li>
                    <li className="tw-flex tw-flex-wrap tw-items-start tw-justify-center tw-gap-x-5">
                      <div className="circle tw-bg-yellow-3 tw-mt-1"></div>
                      <div className="tw-flex-1 tw-space-y-2">
                        <p className="tw-break-all tw-font-black tw-tracking-wide">
                          {t('common:farmingSupply')}
                        </p>
                        <p>300,000,000,000,000</p>
                      </div>
                    </li>
                    <li className="tw-flex tw-flex-wrap tw-items-start tw-justify-center tw-gap-x-5">
                      <div className="circle tw-bg-gray-2 tw-mt-1"></div>
                      <div className="tw-flex-1 tw-space-y-2">
                        <p className="tw-break-all tw-font-black tw-tracking-wide">
                          {t('common:launchLiquidity')}
                        </p>
                        <p>200,000,000,000,000</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="tabs__content" data-tabcontent="edao">
            <div className="tw-overflow-hidden tw-relative tw-rounded-4xl">
              <div
                className="tw-absolute tw-bg-center tw-bg-cover tw-bg-no-repeat tw-inset-0 tw-w-full"
                style={{ backgroundImage: `url(${BgTokenEdoge})` }}
              />
              <div className="tw-flex tw-flex-col tw-justify-center tw-mx-auto tw-py-6 lg:tw-py-20 xl:tw-py-40 tw-relative tw-gap-y-8 sm:tw-gap-y-16 tw-w-11/12 xl:tw-w-10/12">
                <div className="tw-flex tw-flex-wrap tw-items-center tw-space-x-7">
                  <img src={Statistics} alt="Statistics" />
                  <h3 className="heading">{t('common:statistics')}</h3>
                </div>
                <div className="tw-max-w-lg tw-space-y-6 sm:tw-space-y-8">
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:price')}</p>
                    <p className="tw-font-bold">$60</p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:holders')}</p>
                    <p className="tw-font-bold">100+</p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">
                      {t('common:twitterFollows')}
                    </p>
                    <p className="tw-font-bold">51,000+</p>
                  </div>
                  <div className="tw-flex tw-flex-wrap tw-flex-col sm:tw-flex-row tw-justify-between tw-items-start sm:tw-items-center tw-gap-y-1 tw-text-2xl">
                    <p className="tw-text-purple-2">{t('common:telegramUsers')}</p>
                    <p className="tw-font-bold">45,000+</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tabs__content" data-tabcontent="eswap">
            <img className="tw-w-full" src={BgTokenEswap} alt="Token ESWAP" />
            {/* <p className="text-5xl font-bold text-center">
              {t('common:comingSoon')}
            </p> */}
          </div>

          <div className="tabs__content" data-tabcontent="eplay">
            <img className="tw-w-full" src={BgTokenEplay} alt="Token EPLAY" />
            {/* <p className="text-5xl font-bold text-center">
              {t('common:comingSoon')}!
            </p> */}
          </div>
        </div>

        <div className="tw-mt-12 lg:tw-mt-20 xl:tw-mt-40">
          <h3 className="tw-main-heading tw-text-center">
            {t('home.edoge.questionMarks.title')}
          </h3>
          <div className="tw-mt-12 lg:tw-mt-16 xl:tw-mt-20">
            <img
              className="tw-w-full"
              src={QuestionMarks}
              alt={t('home.edoge.questionMarks.title')}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation(['translation', 'common'])(Tokens);
