import React, { useState, createRef, useRef } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Overlay,
  Container,
  Row,
} from 'react-bootstrap';
import Wallet from './Wallet';
import Account from './Account';
import edoge from '../../assets/img/edoge.svg';

const Header = (props) => {
  const { account, error } = props;
  const [ language, setLanguage ] = useState('English');
  const [ show, setShow ] = useState(false);
  const target = useRef(null);
  
  const walletRef = createRef();

  const openWalletModal = () => {
    walletRef.current.handleOpen();
  };

  return (
    <>
      <Navbar variant="dark" expand="md" >
        <Navbar.Brand href="/">
          <img src={edoge} className="tw-inline-block tw-align-top" alt="Elondoge Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="tw-mr-auto">
            <Nav.Link href="/app/farm">Farm</Nav.Link>
            <Nav.Link href="#">Buy $EDOGE</Nav.Link>
            <Nav.Link href="#">Buy $EDAO</Nav.Link>
          </Nav>
          <Nav className="tw-mr-4 tw-hidden lg:tw-flex">
            {account ? (
              <Account account={account} />
            ) : (
              <Button bsPrefix="elondoge-btn" onClick={openWalletModal}>Connect Wallet</Button>
            )}
          </Nav>
          <Nav onSelect={setLanguage} variant="pills" className="tw-mr-4 ">
            <NavDropdown title={language}>
              <NavDropdown.Item eventKey="English">English</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="tw-hidden lg:tw-flex">
            <Nav.Link ref={target} href="#" className="fw-bold" onClick={() => setShow(!show)}>...</Nav.Link>
            <Overlay target={target.current} show={show} placement="bottom-start">
              <Container fluid className="dropdown-nav__container">
                <Row className="dropdown-nav__items">
                  <a href ><span>Docs (Coming Soon)</span></a>
                  <span>Documentation for users of Edoge</span>
                </Row>
                <Row className="dropdown-nav__items"> 
                  <a href><span>Dev (Coming Soon)</span></a>
                  <span>Documentation for developers of Edoge</span>
                </Row>
                <Row className="dropdown-nav__items">
                  <a href><span>Open Source (Coming Soon)</span></a>
                  <span>Edoge is a supporter of Open Source</span>
                </Row>
                <Row className="dropdown-nav__items">
                  <a href><span>Tools (Coming Soon)</span></a>
                  <span>Tools to optimize your workflow</span>
                </Row>
                <Row className="dropdown-nav__items">
                  <a href><span>Discord (Coming Soon)</span></a>
                  <span>Join the community on Discord</span>
                </Row>
                <Row className="dropdown-nav__items">
                  <a href><span>Vesting (Coming Soon)</span></a>
                  <span>Weekly unlocks from the vesting period.</span>
                </Row>
              </Container>
            </Overlay>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Wallet account={account} error={error} ref={walletRef}/>
    </>
  );
}

export default Header;