import React, {
  useState,
  createRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Button,
  FloatingLabel,
  Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { equipNFT } from '../../utils/EthWebProvider';
import BaseModal from '../common/BaseModal';
import '../../assets/css/components/farm/boostform.css';

const modalRef = createRef();
const BoostForm = forwardRef((props, ref) => {
  const [ tokenId, setTokenId ] = useState('');
  const {
    isBoostingLoading,
    setBoostingLoading,
    tokenAddress,
    openSuccessToast,
    openDangerToast,
  } = props;
  useImperativeHandle(ref, () => ({
    handleOpen() {
      modalRef.current.handleOpen();
    }
  }));

  const boostFarm = async () => {
    if (!tokenId) {
      openDangerToast('Please enter token id.');
    } else {
      try {
        setBoostingLoading(true);
        
        await equipNFT(tokenId, tokenAddress);
        setBoostingLoading(false);
        openSuccessToast();
        setTokenId('');
        modalRef.current.handleClose();
      } catch (error) {
        console.error(error);
        setBoostingLoading(false);
        openDangerToast();
      }
    }
  };

  return (
    <BaseModal
      ref={modalRef}
      body={
        <>
          <p className="mb-3 tw-text-xs">
            You can find your token ID from your NFT bscscan/etherscan link
          </p>
          <FloatingLabel
            label="Token ID"
            className="boost__input mb-3"
          >
            <Form.Control
              type="text"
              value={tokenId}
              onChange={(e) => setTokenId(e.target.value)}
            />
          </FloatingLabel>
          <Button
            size="lg"
            disabled={isBoostingLoading}
            bsPrefix="elondoge-btn"
            onClick={boostFarm}
            className="tw-w-full"
          >
            {isBoostingLoading ? (
              <p className="tw-animate-spin h-5 w-5 mr-3">
                <FontAwesomeIcon icon={faCircleNotch} />
              </p>
            ): 'Boost'}
          </Button>
        </>
      }
      contentClass="base-modal" 
      title="Farm Boosting"
    >
    </BaseModal>
  );
});

export default BoostForm;
