import React, {
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';

const CommonToast = forwardRef((props, ref) => {
  const [ show, setShow ] = useState(false);
  const {
    toastVariant,
    infoMessage,
    successMessage,
    dangerMessage,
  } = props;

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setShow(true);
    }
  }));

  let message, headerJsx;
  switch(toastVariant) {
    case 'success':
      message = successMessage;
      headerJsx = <span className="tw-mr-auto">
        <FontAwesomeIcon icon={faCheck} /> Success
      </span>
      break;
    case 'danger':
      message = dangerMessage;
      headerJsx = <span className="tw-mr-auto">
        <FontAwesomeIcon icon={faExclamation} /> Error
      </span>
      break;
    default:
      message = infoMessage;
      headerJsx = <span className="tw-mr-auto">
        Notice
      </span>
      break;
  }

  return (
    <ToastContainer position="top-center">
      <Toast
        bg={toastVariant}
        show={show}
        onClose={() => setShow(false)}
        autohide
      >
        <Toast.Header>
          {headerJsx}
        </Toast.Header>
        <Toast.Body className="text-white">
          {message}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  )
});

export default CommonToast;
