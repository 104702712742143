import React, {
  useState,
  useEffect,
  createRef,
} from 'react';
import Sidemenu from '../common/Sidemenu';
import List from './List';
import CommonToast from '../common/CommonToast';
import {
  edogeSingle,
  edaoBNB,
  edogeEdao,
} from '../../utils/Constants';
import { allowance } from '../../utils/EthWebProvider';

const toastRef = createRef();
const Farm = (props) => {
  const { account } = props;
  const [filters, setFilters] = useState([
    {name: 'All Farms', isEnable: true},
    {name: 'Your Farms', isEnable: false}
  ])
  const [ tokenAddressList, setTokenAddressList ] = useState([
    { tokenAddress: edogeSingle, isFarm: false },
    { tokenAddress: edaoBNB, isFarm: false },
    { tokenAddress: edogeEdao, isFarm: false }
  ]);

  useEffect(() => {
    if( account )
      ( async () => {
        try {
          const edogeSingleIsFarmed = await allowance(edogeSingle);
          const edaoBNBIsFarmed = await allowance(edaoBNB);
          const edogeEdaoIsFarmed = await allowance(edogeEdao);
      
          setTokenAddressList([
            { tokenAddress: edogeSingle, isFarm: ( edogeSingleIsFarmed > 0 ) },
            { tokenAddress: edaoBNB, isFarm: ( edaoBNBIsFarmed > 0 ) },
            { tokenAddress: edogeEdao, isFarm: ( edogeEdaoIsFarmed > 0 ) }
          ]);
        } catch (error) {
          console.error(error);
          toastRef.current.handleOpen();
        }
      })();
  }, [account])

  const handleFilter = (event, key) => {
    setFilters(filters.map((filter, index) => {
      filter.isEnable = (index === key) ? true : false
      return filter
    }))
  }

  return (
    <main className="tw-pt-12 container-fluid tw-px-0 lg:tw-px-3">
      <CommonToast 
        toastVariant="danger" 
        ref={toastRef}
        dangerMessage={"Connect to BSC Network"}
      />
      <div className="row">
        <Sidemenu className="tw-w-1/2 lg:tw-w-1/4" filters={filters} onFilter={handleFilter} />
        <List className="tw-w-full xl:tw-w-3/4" account={account} filters={filters} tokenAddressList={tokenAddressList} />
      </div>
    </main>
  );
}

export default Farm;
