import ElonCapitan from '../../../assets/images/eloncapitan.png';
import DogeMater from '../../../assets/images/dogemater.png';
import MuskBeater from '../../../assets/images/muskbeater.png';
import { withTranslation } from 'react-i18next';

function Team({ t }) {
  return (
    <div className="tw-mt-12 lg:tw-mt-20 xl:tw-mt-40">
      <h3 className="main-heading tw-text-center">{t('common:team')}</h3>
      <div className="tw-flex tw-flex-wrap tw-gap-y-10 tw-items-start tw-justify-around tw-mt-12 lg:tw-mt-16 xl:tw-mt-20">
        <div className="tw-w-full sm:tw-w-1/2 lg:tw-w-auto tw-flex tw-flex-col tw-items-center tw-space-y-7">
          <img src={ElonCapitan} alt="ElonCapitan" />
          <p className="tw-font-semibold">ElonCapitan</p>
        </div>
        <div className="tw-w-full sm:tw-w-1/2 lg:tw-w-auto tw-flex tw-flex-col tw-items-center tw-space-y-7">
          <img src={DogeMater} alt="DogeMater" />
          <p className="tw-font-semibold">DogeMater</p>
        </div>
        <div className="tw-w-full sm:tw-w-1/2 lg:tw-w-auto tw-flex tw-flex-col tw-items-center tw-space-y-7">
          <img src={MuskBeater} alt="MuskBeater" />
          <p className="tw-font-semibold">MuskBeater</p>
        </div>
      </div>
    </div>
  );
}

export default withTranslation(['translation', 'common'])(Team);
