export const numberWithComma = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const truncate = (fullStr, strLen, separator) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow/2),
    backChars = Math.floor(charsToShow/2);

  return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
};

export const inputDecimalValidation = (evt) => {
  const charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode !== 46 && charCode > 31 
    && (charCode < 48 || charCode > 57))
      return false;

  return true;
}
