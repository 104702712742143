import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import '../../assets/css/components/common/basemodal.css';

const BaseModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const { title, body, contentClassName, dialogClassName, closeModal } = props;


  useEffect(() => {
    if(closeModal)
      handleClose()
  }, [closeModal])

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setShow(true);
    },
    handleCloseModal() {
      setShow(false);
    }
  }));

  const handleClose = () => {
    setShow(false);
  };
  
  return (
    <>
      <Modal 
        show={show} 
        onHide={handleClose} 
        centered 
        contentClassName={contentClassName ? contentClassName : "base-modal"} 
        dialogClassName={dialogClassName ? dialogClassName : "base-modal-dialog"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
      </Modal>
    </>
  );
});
export default BaseModal;