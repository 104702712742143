import React, {
  createRef, 
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';

import BaseModal from './BaseModal';

const modalRef = createRef();
const AnalyticsModal = forwardRef((props, ref) => {
  const [ closeModal ] = useState(true)
  const webvitals = JSON.parse(sessionStorage.getItem('webvitals'))

  useImperativeHandle(ref, () => ({
    handleOpen() {
      modalRef.current.handleOpen();
    }
  }));

  const ModalBody = () => {
    return(
      <div className="tw-flex tw-flex-wrap tw-items-center tw-flex-wrap tw-justify-center">
        {
          Object.values(webvitals).map(webvital => {
            return (
              <div key={webvital.name} className="tw-flex tw-w-1/2 tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-w-auto tw-block tw-text-center tw-text-4xl mb-2">{webvital.name}</h2>
                <span className="tw-w-auto tw-block tw-text-center">{webvital.value.toFixed(3)}</span>
              </div>
            )
          })
        }
      </div>
    );
  }

  return (
    <>
      <BaseModal 
        ref={modalRef}
        closeModal={closeModal}
        body={<ModalBody />} 
        contentClass="base-modal" 
        title="Analytics"
      />
    </>
  )
});


export default AnalyticsModal;