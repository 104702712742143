import { memo } from 'react';
import ElonDoge from '../../../assets/images/footer-elondoge.png';
import ElonCapitan from '../../../assets/images/footer-eloncapitan.png';
import DogeMater from '../../../assets/images/footer-dogemater.png';
import MuskBeater from '../../../assets/images/footer-muskbeater.png';
import { withTranslation } from 'react-i18next';

const currentYear = new Date().getFullYear();

function Footer({ t, languages, selectedLanguage, handleLanguageChange }) {
  return (
    <footer className="tw-bg-blue-1">
      <div className="tw-w-11/12 xl:tw-w-10/12 tw-mx-auto tw-space-y-14 lg:tw-space-y-16 xl:tw-space-y-28 tw-pt-10 lg:tw-pt-16 xl:tw-pt-32 tw-pb-8 lg:tw-pb-10 xl:tw-pb-12">
        <div className="tw-flex tw-flex-wrap tw-flex-col xl:tw-flex-row tw-items-center xl:tw-items-start tw-justify-between tw-gap-x-10 tw-gap-y-16">
          <div className="tw-w-full xl:tw-w-5/12 tw-space-y-10">
            <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center xl:tw-justify-start tw-space-x-7">
              <img src={ElonDoge} alt="ElonDoge Footer" />
              <h2>ElonDoge</h2>
            </div>
            <p className="tw-text-center xl:tw-text-left">
              {t('common:footer.copy')}
            </p>
            <div className="tw-flex tw-flex-wrap tw-justify-center xl:tw-justify-start tw-gap-x-5 xl:tw-gap-x-20 tw-gap-y-16">
              <img src={ElonCapitan} alt="ElonCapitan Footer" />
              <img src={DogeMater} alt="DogeMater Footer" />
              <img src={MuskBeater} alt="MuskBeater Footer" />
            </div>
          </div>
          <div className="xl:tw-w-6/12 tw-text-base sm:tw-text-lg tw-flex tw-flex-wrap tw-items-start tw-gap-x-5 sm:tw-gap-x-8 tw-gap-y-8 tw-justify-center xl:tw-justify-between">
            <nav>
              <ul className="tw-flex-1 tw-space-y-4">
                <li className="tw-font-semibold tw-pb-2">{t('common:socials')}</li>
                <li>
                  <a
                    href="https://t.me/elondogeio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/elondogeio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCx_soNYxc0qAdD0XRuxTwjw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Youtube
                  </a>
                </li>
              </ul>
            </nav>
            <nav>
              <ul className="tw-flex-1 tw-space-y-4">
                <li className="tw-font-semibold tw-pb-2">{t('common:blockchain')}</li>
                <li>
                  <a
                    href="https://coinmarketcap.com/currencies/elondoge/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CoinMarketCap
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.coingecko.com/en/coins/elon-doge-token"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CoinGecko
                  </a>
                </li>
                <li>
                  <a
                    href="https://opensea.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Opensea
                  </a>
                </li>
                <li>
                  <a
                    href="https://bscscan.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BSCScan
                  </a>
                </li>
                <li>
                  <a
                    href="https://pancakeswap.finance/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pancakeswap
                  </a>
                </li>
              </ul>
            </nav>
            <nav>
              <ul className="tw-flex-1 tw-space-y-4">
                <li className="tw-font-semibold tw-pb-2">{t('common:usefulLink')}</li>
                <li>
                  <a
                    href="https://bscscan.com/address/0x163f182c32d24a09d91eb75820cde9fd5832b329#code"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('common:contract')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://docsend.com/view/a55zk98x49rnjd62"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Woofpaper
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:elondogeproject@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('common:contact')}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="tw-relative tw-flex tw-flex-col tw-gap-y-8">
          <p className="tw-flex-1 tw-text-base sm:tw-text-lg tw-text-center">
            ElonDoge &copy; {t('common:allRightsReserved')} {currentYear}
          </p>
          <div className="tw-static xl:tw-absolute tw-inset-y-0 tw-right-0 tw-items-center tw-justify-center tw-flex tw-order-first xl:tw-order-last">
            <select
              className="tw-cursor-pointer tw-text-white tw-bg-transparent"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              {languages.map((_lang, i) => (
                <option key={i} className="tw-text-black" value={_lang.code}>
                  {_lang.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default withTranslation(['translation', 'common'])(memo(Footer));
