import { createRef } from 'react';
import Sprite from '../../assets/images/sprite.svg';
import { withTranslation } from 'react-i18next';

function ComingSoonModal({ t, showModal, setShowModal }) {
  const modalRef = createRef();

  const closeModal = () => {
    const modal = modalRef.current;
    modal.classList.remove('active');
    setShowModal(false);
  }

  return (
    <>
      <div id="coming-soon-modal" className={`${showModal ? 'active' : ''}`} ref={modalRef}>
        <div className="tw-relative tw-w-full tw-bg-white tw-text-black tw-py-20 tw-px-6 tw-rounded-xl tw-mx-auto">
          <span className="tw-absolute tw-right-5 tw-top-5 tw-cursor-pointer" onClick={closeModal}>
            <svg className="tw-w-6 tw-h-6 tw-text-black">
              <use xlinkHref={`${Sprite}#times`} />
            </svg>
          </span>
          <p className="tw-heading tw-font-semibold tw-text-center">{t('common:comingSoon')}</p>
        </div>
      </div>
      <div className="modal-backdrop" onClick={closeModal}></div>
    </>
  )
}

export default withTranslation(['translation', 'common'])(ComingSoonModal);