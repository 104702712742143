import { createRef, memo } from 'react';
import PropTypes from 'prop-types';

function Accordion({ className = '', title, panel, ...rest }) {
  const ref = createRef();

  const handleOnClick = () => {
    ref.current.classList.toggle('accordion__panel--active');
  }

  return (
    <div className={`accordion ${className}`} {...rest}>
      <div className="accordion__title" onClick={handleOnClick}>{title}</div>
      <div className="accordion__panel" ref={ref}>
        <div className="pt-4">{panel}</div>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.element.isRequired,
};

export default memo(Accordion);
