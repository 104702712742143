import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import Header from './components/common/Header';
import HeaderMenuMobile from './components/common/HeaderMenuMobile';
import Footer from './components/common/Footer';
import Home from './components/home';
import Farm from './components/farm';

function App(props) {
  const { account, error } = useEthers();
  const { pathname } = props.location;

  return (
    <>
      <div id="app">
        <Route exact path="/" component={() => <Home />} />
        {pathname !== '/' && (
          <>
          <div className="container-fluid lg:tw-pb-0 tw-pb-32 tw-px-10">
            <Header account={account} error={error} />
            <Route
              exact
              path="/farm"
              component={() => <Farm account={account} />}
            />
            <Footer />
          </div>
          <div>
            <HeaderMenuMobile account={account} error={error}/>
          </div>
          </>
        )}
      </div>
    </>
  );
}

export default withRouter(App);
