import React, {
  createRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react';
import { useEthers } from '@usedapp/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import CommonToast from './CommonToast';
import { connectSuccessMessage, connectErrorMessage } from '../../utils/Constants';
import metaMask from '../../assets/img/MetaMask.svg';
import BaseModal from './BaseModal';
import warning from '../../assets/img/warning.svg';

const toastRef = createRef();
const modalRef = createRef();
const Wallet = forwardRef((props, ref) => {
  const { error, account } = props
  const { activateBrowserWallet } = useEthers();
  const [ toastVariant, setToastVariant ] = useState('success');
  const [ hasError, setHasError ] = useState(false)
  const [ closeModal, setCloseModal ] = useState(false)
  const [ isConnectionLoading, setIsConnectionLoading ] = useState(false)

  useEffect(() => {
    if(error && ((
      !isConnectionLoading
        && !error.message.includes('wallet_requestPermissions')
      ) || error.name)) {
        setHasError(true)
        setIsConnectionLoading(false)
        setToastVariant('danger');
    }

    if(account) {
      setToastVariant('success');
      setIsConnectionLoading(false)
      setCloseModal(true)
    }
    
  }, [
    error,
    account,
    isConnectionLoading,
  ]);


  useImperativeHandle(ref, () => ({
    handleOpen() {
      modalRef.current.handleOpen();
    }
  }));

  const connectToMetaMask = async() => {
    setIsConnectionLoading(true);
    activateBrowserWallet();
  };

  const ErrorMessage = () => {
    return (
      <div className="error-connection tw-flex tw-items-center tw-justify-between tw-w-full tw-mb-4 tw-p-4 tw-border tw-rounded-xl tw-border-red-600">
        <span>
          <img src={warning} alt="warning" />
          <span className="tw-text-red-600 tw-font-family-poppins tw-pl-4">Error Connecting</span>
        </span>
        <button 
          className="elondoge-btn elondoge-btn-primary tw-text-sm"
          onClick={connectToMetaMask}
        >
          Try Again
        </button>
      </div>
    )
  }

  const ModalBody = () => {
    return(
      <div className="tw-flex tw-items-center tw-flex-col">
        {hasError && <ErrorMessage />}
        <button
          className="elondoge-btn elondoge-btn-secondary elondoge-btn-lg tw-flex tw-items-center tw-justify-between tw-w-full"
          onClick={connectToMetaMask}
          disabled={isConnectionLoading}
        >
          {isConnectionLoading ? (
            <p className="tw-animate-spin h-5 w-5 mr-3 mx-auto">
              <FontAwesomeIcon icon={faCircleNotch} />
            </p>
          ): (
            <>
              <span>Metamask</span>
              <img src={metaMask} alt="MetaMask icon" />
            </>
          )}
        </button>
      </div>
    );
  }

  return (
    <>
      <CommonToast 
        toastVariant={toastVariant} 
        ref={toastRef}
        successMessage={connectSuccessMessage}
        dangerMessage={connectErrorMessage}
      />
      <BaseModal 
        ref={modalRef}
        closeModal={closeModal}
        body={<ModalBody />} 
        contentClass="base-modal" 
        title="Connect a Wallet"
      />
    </>
  );
});

export default Wallet;
