import React, {useState, useEffect} from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import '../../assets/css/components/common/sidemenu.css';

const SideMenu = (props) => {
  const { filters, className, onFilter } = props;
  const [dropdownLabel, setDropDownLabel] = useState('All Farms')
  

  useEffect(() => {
    if(filters) {
      const filtered = filters.find(filter => filter.isEnable)
      setDropDownLabel(filtered.name)
    }
  }, [filters])

  return (
      <>
        <div className='tw-block xl:tw-hidden'>
          <Dropdown>
             <Dropdown.Toggle id="dropdown-autoclose-true" className={`sidemenu sidemenu--mobile tw-rounded-2xl ${className}`}>
                {dropdownLabel}
              </Dropdown.Toggle> 

              <Dropdown.Menu>
                {[...filters].map((filter, index) => 
                  <Dropdown.Item key={index} onClick={(e) => onFilter(e, index)}>{filter.name}</Dropdown.Item>
                )}
              </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={`sidemenu ${className} tw-hidden xl:tw-block`}>
          <Nav className="tw-flex-col">
            {[...filters].map((filter, index) => 
              <Nav.Link className={filter.isEnable ? 'active' : ''} key={index} onClick={(e) => onFilter(e, index)}>{filter.name}</Nav.Link>)
            }
          </Nav>
        </div>
      </>
  );
}

export default SideMenu;
