import React, { useState, createRef, useRef } from 'react';
import {
  Nav,
  Button,
  Overlay,
  Container,
  Row,
} from 'react-bootstrap';
import Wallet from './Wallet';
import Account from './Account';

const HeaderMenuMobile = (props) => {
  const { account, error } = props;
  const [ toggle, setToggle ] = useState(false);
  const target = useRef(null);
  
  const walletRef = createRef();

  const openWalletModal = () => {
    walletRef.current.handleOpen();
  };

  return (
    <>
      <div className="dropdown-nav--mobile tw-flex lg:tw-hidden">
        <Nav className="tw-flex-col tw-flex tw-flex-nowrap tw-justify-center lg:tw-flex-wrap lg:tw-justify-between lg:tw-mr-4">
          {account ? (
            <Account account={account} />
          ) : (
            <Button bsPrefix="elondoge-btn" onClick={openWalletModal}>Connect Wallet</Button>
          )}
        </Nav>
        <Nav className="tw-flex lg:tw-hidden tw-justify-center">
          <Nav.Link ref={target} href="#" className="tw-font-bold" onClick={() => setToggle(!toggle)}>...</Nav.Link>
          <Overlay target={target.current} show={toggle} placement="top-start">
            <Container fluid className="dropdown-nav__container mobile tw-flex lg:tw-hidden">
              <Row className="dropdown-nav__items">
                <a href ><span>Docs (Coming Soon)</span></a>
                <span>Documentation for users of Edoge</span>
              </Row>
              <Row className="dropdown-nav__items"> 
                <a href><span>Dev (Coming Soon)</span></a>
                <span>Documentation for developers of Edoge</span>
              </Row>
              <Row className="dropdown-nav__items">
                <a href><span>Open Source (Coming Soon)</span></a>
                <span>Edoge is a supporter of Open Source</span>
              </Row>
              <Row className="dropdown-nav__items">
                <a href><span>Tools (Coming Soon)</span></a>
                <span>Tools to optimize your workflow</span>
              </Row>
              <Row className="dropdown-nav__items">
                <a href><span>Discord (Coming Soon)</span></a>
                <span>Join the community on Discord</span>
              </Row>
              <Row className="dropdown-nav__items">
                <a href><span>Vesting (Coming Soon)</span></a>
                <span>Weekly unlocks from the vesting period.</span>
              </Row>
            </Container>
          </Overlay>
        </Nav>
      </div>
      <Wallet account={account} error={error} ref={walletRef}/>
    </>
  );
}
export default HeaderMenuMobile;