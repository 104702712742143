import React, { createRef, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { truncate } from '../../utils/GeneralUtils';
import { useEtherBalance, useEthers } from '@usedapp/core';
import { formatEther } from '@ethersproject/units'
import { ChainId } from '@usedapp/core';
import AccountLogo from '../../assets/img/account-logo.svg';
import AccountInfo from './AccountInfo';
import BaseModal from './BaseModal';
import '../../assets/css/components/common/account.css';


const Account = (props) => {
  const { account } = props;
  const { chainId: currentChain } = useEthers();

  let defaultCurrency;
  if (currentChain === 4) {
    defaultCurrency = 'Ethereum';
  } else if (currentChain === 56) {
    defaultCurrency = 'BSC';
  }

  const [ currency, setCurrency ] = useState(defaultCurrency);
  const truncatedAccount = truncate(account, 12);
  const etherBalance = useEtherBalance(account);

  // For Base Modal
  const modalRef = createRef();

  const openModal = () => {
    modalRef.current.handleOpen();
  };

  const displayAccountBalance = () => {
    let balance = '';
    switch (currentChain) {
      case 4:
        if(etherBalance) {
          const formatedEthBalance = formatEther(etherBalance);
          balance = `${formatedEthBalance} ETH`;
        }
        break;
      case 56: 
        if(etherBalance) {
          const formatedEthBalance = formatEther(etherBalance);
          balance = `${formatedEthBalance} BNB`;
        }
        break;
        
      default:
        break;
    }
    return(balance);
  }

  const handleChangeCurrency = async (eventKey) => {
    if(window.ethereum) {
      if (eventKey === 'Ethereum') {
        const chainId = ChainId.Rinkeby;
        const chanIdHex = `0x${parseInt(chainId, 10).toString(16)}`;

        setCurrency(eventKey);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chanIdHex }], // chainId must be in hexadecimal numbers
        });
        
      } else {
        const chainId = ChainId.BSC;
        const chanIdHex = `0x${parseInt(chainId, 10).toString(16)}`;

        setCurrency(eventKey);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chanIdHex }], // chainId must be in hexadecimal numbers
        }); 
      }
    }
  }

  return (
    <>
      
      <div className="tw-mb-2 tw-px-20 tw-flex tw-flex-col lg:tw-hidden">
        <div className="account__container tw-flex tw-justify-center tw-mb-2">
          <div className="account__balance tw-mr-4">
            <span>{displayAccountBalance()}</span>
          </div>
        </div>
        <div className="account__name tw-flex tw-justify-center tw-w-full lg:tw-w-auto">
            <span 
              className="me-3" 
              onClick={openModal}
            >
                {truncatedAccount}
            </span>
            <img src={AccountLogo} alt="Account Logo" />
          </div>
      </div>
      <Nav onSelect={handleChangeCurrency} variant="pills" className="tw-mr-4 tw-justify-center">
        <NavDropdown title={currency}>
          <NavDropdown.Item eventKey="BSC">BSC</NavDropdown.Item>
          <NavDropdown.Item eventKey="Ethereum" className="tw-hidden lg:tw-inline-block">ETH</NavDropdown.Item>
          <NavDropdown.Item eventKey="Ethereum" className="tw-inline-block lg:tw-hidden">Ethereum</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <div className="account__container tw-hidden lg:tw-flex">
        <div className="account__balance tw-mr-4">
          <span>{displayAccountBalance()}</span>
        </div>
        <div className="account__name">
          <span className="me-3" >
              {truncatedAccount}
          </span>
          <img
            src={AccountLogo}
            alt="Account Logo"
            onClick={openModal}
          />
        </div>
      </div>
      <BaseModal 
        ref={modalRef} 
        body={<AccountInfo account={account} />} 
        contentClass="base-modal" 
        title="Account"
      />
    </>
  )
}
export default Account;