import BannerImg from '../../../assets/images/banner-clean.png';
import BannerIllustration from '../../../assets/images/banner-illustration.svg';
import Icon from '../../../assets/images/elondoge-logo.png';
import Button from '../../../components/common/Button';
import { withTranslation } from 'react-i18next';

function Banner({ t }) {
  return (
    <div className="banner tw-relative tw-pt-28 lg:tw-pt-36 xl:tw-pt-48 xl:tw-pt-60">
      <div
        className="tw-absolute tw-bg-top tw-bg-cover tw-bg-no-repeat tw-inset-0 tw-w-full"
        style={{ backgroundImage: `url(${BannerImg})` }}
      />

      <div className="tw-relative tw-w-11/12 xl:tw-w-10/12 tw-mx-auto tw-text-center">
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-10">
          <div className="tw-flex tw-flex-col tw-items-center">
            <img src={Icon} alt="" />
            <h1>ElonDoge</h1>
          </div>
          <p>{t('home.banner.copy')}</p>
          <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-y-5 tw-gap-x-10">
            <a
              href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x163f182c32d24a09d91eb75820cde9fd5832b329"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="yellow" size="lg">
                {t('common:buy')} Pancakeswap
              </Button>
            </a>
            <a
              href="https://www.hotbit.io/exchange?symbol=EDOGE_nUSD"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="turquoise" size="lg">
                {t('common:buyAt')} Hotbit
              </Button>
            </a>
          </div>
        </div>
      </div>

      <div className="tw-relative tw-mt-10 lg:tw-mt-20 xl:tw-mt-32">
        <div className="tw-text-center">
          <h2>{t('home.banner.how')}</h2>
          <div className="tw-mt-6 lg:tw-mt-10 xl:tw-mt-20">
            <img
              className="tw-w-full"
              src={BannerIllustration}
              alt={t('home.banner.how')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation(['translation', 'common'])(Banner);
